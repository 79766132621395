import React, { useContext, useEffect, useState } from 'react'
import EmployeeDrop from './EmployeeDrop'
import { Store } from '../Context/StateStore'
import axios from 'axios'
import { hostName } from '../App'

const NotifyMessage = () => {
    const { employee } = useContext(Store)
    const [allEmployees, setAllEmplyee] = useState()
    const [reciver, setreciever] = useState('')
    let [message,setMessage]=useState('')
    useEffect(() => {
        axios.get(`${hostName}/api/EmployeeRegistration/`).then((response) => {
            setAllEmplyee(response.data)
            console.log(response.data);
        }).catch((error) => console.log(error))
    }, [])
    let sendNotification=()=>{
        axios.post(`${hostName}/api2/MessageView/${JSON.parse(sessionStorage.getItem('dasid'))}/`,{
            message:message,
            receiver:reciver
        }).then((response)=>{
            console.log(response.data);
            alert(response.data)
            setMessage('')
        }).catch((error)=>{
            console.log(error);
        })
    }
    return (
        <div className='sticky  h-[7vh] bottom-0 bg-white w-full p-2 mb-0 '>
            {reciver != '' &&
                <input type="text" value={message} onChange={(e)=>setMessage(e.target.value)} 
                className='border-1 p-2 px-3 border-slate-600 rounded-xl outline-none ' placeholder='Send a notification' />}

            {allEmployees && reciver == '' &&
                <select name="" id="" className='outline-none ' onChange={(e) => setreciever(e.target.value)} >
                    <option value="">Select</option>

                    {allEmployees.filter((id)=>JSON.parse(sessionStorage.getItem('dasid'))!=id.employee_id).map((x) => {
                        console.log(x);
                        return (
                            <option value={x.employee_id}>{x.name} ({x.position.position})  </option>
                        )
                    })}

                </select>
            }
            <button onClick={sendNotification} className='p-2 px-3 bg-blue-500 rounded text-white mx-2'>
                send
            </button>
            {reciver && <button onClick={() => setreciever('')} className=' p-2 px-3 bg-slate-500 text-white rounded'>
                back
            </button>}


        </div>
    )
}

export default NotifyMessage