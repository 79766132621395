import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { hostName } from '../../App'
import { tr } from 'date-fns/locale'

const ViewTaskData = ({ show, setshow, subTask, setSubTask }) => {
    let [data, setData] = useState()
    let [loading, setLoading] = useState(false)

    let getData = () => {
        setLoading(true)
        axios.get(`${hostName}/api/ProjectDetails/${show}`).then((response) => {
            console.log(response.data);
            setData(response.data)
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false)

        })
    }
    let getData2 = () => {
        setLoading(true)
        axios.get(`${hostName}/api/SubTaskDetails/${subTask}`).then((response) => {
            console.log(response.data);
            setData(response.data)
            setLoading(false)

        }).catch((error) => {
            console.log(error);
            setLoading(false)

        })
    }

    useEffect(() => {
        if (show)
            getData()
        if (subTask)
            getData2()
    }, [show, subTask])
    return (
        <div>
            <Modal className=' ' centered show={show || subTask} onHide={() => { setshow(false); setSubTask(false) }} >
                <Modal.Header closeButton>
                    Task Details
                </Modal.Header>
                <Modal.Body>
                    {
                        loading ? <div className='min-h-[60vh] flex ' >
                            <Spinner className='m-auto ' />
                        </div> : data &&
                        <main className='max-h-[60vh] overflow-y-scroll scrollbar ' >
                            <h4> Task Details </h4>
                            <div className='flex  ' >
                                <p className='w-40' >Task Name : </p>
                                {data.task_name}
                            </div>
                            <div className='flex ' >
                                <p className='w-40' >Assigned To : </p>
                                {(show && data.assigned_to) ? data.assigned_to.name :
                                    data.projectdetails.assigned_to && data.projectdetails.assigned_to.name}
                            </div>
                            <div className='flex  ' >
                                <p className='w-40' >Assigned By : </p>
                                {show ? data.created_by : data.created_by.name}
                            </div>
                            <div className='flex  ' >
                                <p className='w-40' >Created Date: </p>
                                {data.created_date && data.created_date.slice(0, 10)}
                            </div>
                            <div className='flex  ' >
                                <p className='w-40' >Due Date: </p>
                                {data.due_date && data.due_date.slice(0, 10)}
                            </div>
                            <div className='flex  ' >
                                <p className='w-40' >Last Submitted Date: </p>
                                {data.completed_date && data.completed_date.slice(0, 10)}
                            </div>
                            <div className='flex  ' >
                                <p className='w-40' >Status : </p>
                                {data.status}
                            </div>
                            <div className='flex  ' >
                                <p className='w-40' >Remarks : </p>
                                {data.remarks}
                            </div>
                            {
                                data && data.subtasks && <>
                                    <h4>Subtasks for the Task </h4>
                                    <div className='table-responsive ' >
                                        <table className='tablebg w-full ' >
                                            <tr>
                                                <th>SI No </th>
                                                <th>Task Name </th>
                                                <th>Remark </th>
                                                <th>Created by </th>
                                                <th>Created Date </th>
                                                <th>Due date </th>
                                                <th>Completed Date </th>
                                                <th>Status </th>
                                            </tr>
                                            {data.subtasks.map((obj, index) => (
                                                <tr>
                                                    <td> {index + 1} </td>
                                                    <td> {obj.task_name} </td>
                                                    <td> {obj.remarks} </td>
                                                    <td> {obj.created_by.name} </td>
                                                    <td> {obj.created_date && obj.created_date.slice(0, 10)} </td>
                                                    <td> {obj.due_date && obj.due_date.slice(0, 10)} </td>
                                                    <td> {obj.completed_date && obj.completed_date.slice(0, 10)} </td>
                                                    <td> {obj.status} </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>

                                </>
                            }

                        </main>
                    }

                </Modal.Body>

            </Modal>

        </div>
    )
}

export default ViewTaskData