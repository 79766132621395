import React from 'react'

const MessageIcon = ({size}) => {
    return (
        <svg width={size?size:"20"}  height={size?size:"20"} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.585 12.1536C15.585 12.5616 15.4302 12.953 15.1545 13.2415C14.8788 13.53 14.5049 13.6921 14.1151 13.6921H5.29537L2.35547 16.769V4.46131C2.35547 4.05329 2.51034 3.66197 2.78601 3.37346C3.06168 3.08494 3.43557 2.92285 3.82542 2.92285H14.1151C14.5049 2.92285 14.8788 3.08494 15.1545 3.37346C15.4302 3.66197 15.585 4.05329 15.585 4.46131V12.1536Z" stroke="white" stroke-width="1.53844" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default MessageIcon