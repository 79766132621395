import React, { useContext, useEffect, useState } from 'react'
import PlusIcon from '../SVG/PlusIcon'
import axios from 'axios'
import { hostName } from '../App'
import { Store } from '../Context/StateStore'
import DownloadButton from './DownloadButton'

const StickyNotes = () => {
    let { user } = useContext(Store)
    let [message, setMessage] = useState([
    ])
    let handleChange = (e, id) => {
        let { value } = e.target
        let findIndex = message.findIndex((obj) => obj.id == id)
        let arry = [...message]
        arry[findIndex].note = value
        setMessage(arry)
        axios.patch(`${hostName}/api2/NotesViewSet/${JSON.parse(sessionStorage.getItem('daspk'))}/`, {
            id: id,
            note: value,
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let handleDelete = (id) => {
        axios.delete(`${hostName}/api2/NotesViewSet/${id}`).then((response) => {
            console.log(response.data)
            getMessage()
        }).catch((error) => {
            console.log(error);
        })
    }
    let getMessage = () => {
        axios.get(`${hostName}/api2/NotesViewSet/${JSON.parse(sessionStorage.getItem('daspk'))}/`).then((response) => {
            setMessage(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    let handleSave = () => {
        if (user) {
            axios.post(`${hostName}/api2/NotesViewSet/${JSON.parse(sessionStorage.getItem('daspk'))}/`, {
                note: '',
            }).then((response) => {
                console.log(response.data);
                getMessage()
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    useEffect(() => {
        if (user) { getMessage() }
    }, [user])
    let clearAll = () => {
        axios.post(`${hostName}/api2/DeleteAllNotes/${JSON.parse(sessionStorage.getItem('dasid'))}/`, message.map((obj) => obj.id)).then((response) => {
            getMessage()
        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <div className='w-full p-3 rounded bg-white '>
            <h4 className='text-center'>Sticky notes </h4>
            <div className='h-[40vh] overflow-y-scroll scrollbar '>
                {
                    message.map((obj, index) => {
                        return (
                            <div className='my-2 flex items-start'>
                                <span> {index + 1}</span>
                                <textarea type="text" key={index} value={obj.note} placeholder='Add the Notes'
                                    className='w-full mx-2 outline-none ' onChange={(e) => handleChange(e, obj.id)} />
                                <button onClick={() => handleDelete(obj.id)} className='rotate-45 text-red-600 border-1 p-1 rounded-full border-red-600 ' >
                                    <PlusIcon size={10} />
                                </button>
                            </div>
                        )
                    })
                }
            </div>
            <div className='justify-between flex items-center '>
                <button onClick={handleSave} className='p-2 px-3 h-fit bg-blue-500 text-white rounded'>
                    Add the Note </button>

                <button onClick={clearAll} className='p-2 px-3 h-fit rounded bg-slate-500 text-white'>Clear All </button>
                <DownloadButton data={message} type='notes' name={'Notes'} />
            </div>

        </div>
    )
}

export default StickyNotes