import React from 'react'

const BasketIcon = ({size}) => {
    return (
        <svg width={size?size:"38"} height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.2845 5.84064H31.5521L25.3633 1.01978C24.8689 0.634682 24.0632 0.634682 23.5506 1.01978C23.0562 1.40488 23.0562 2.03245 23.5506 2.43181L27.9267 5.84064H10.1292L14.5053 2.43181C14.9997 2.04671 14.9997 1.41914 14.5053 1.01978C14.011 0.634682 13.2053 0.634682 12.6926 1.01978L6.5221 5.84064H5.78969C4.14178 5.84064 0.717773 5.84064 0.717773 9.49194C0.717773 10.8754 1.08398 11.7883 1.853 12.3873C2.29245 12.7439 2.82344 12.9293 3.39106 13.0291C3.92205 13.129 4.48967 13.1432 5.03897 13.1432H33.0169C33.5845 13.1432 34.1155 13.1147 34.6282 13.0291C36.1663 12.7439 37.3381 11.8881 37.3381 9.49194C37.3381 5.84064 33.9141 5.84064 32.2845 5.84064Z" fill="#EA79BA" />
            <path d="M31.9369 14.9976H5.97309C4.83786 14.9976 3.97728 15.782 4.16038 16.6521L5.69843 23.9832C6.21112 26.4364 7.58438 29.2605 13.6817 29.2605H23.9537C30.1242 29.2605 31.2228 26.85 31.882 24.1543L33.7313 16.6948C33.951 15.8105 33.0904 14.9976 31.9369 14.9976ZM16.4831 24.1971C16.4831 24.7534 15.9155 25.1955 15.2197 25.1955C14.5056 25.1955 13.938 24.7534 13.938 24.1971V19.4904C13.938 18.9484 14.5056 18.492 15.2197 18.492C15.9155 18.492 16.4831 18.9484 16.4831 19.4904V24.1971ZM24.3199 24.1971C24.3199 24.7534 23.7523 25.1955 23.0382 25.1955C22.3424 25.1955 21.7565 24.7534 21.7565 24.1971V19.4904C21.7565 18.9484 22.3424 18.492 23.0382 18.492C23.7523 18.492 24.3199 18.9484 24.3199 19.4904V24.1971Z" fill="#EA79BA" />
        </svg>
    )
}

export default BasketIcon