import React from 'react'

const SendIcon = ({ size }) => {
    return (

        <svg width={size ? size : "16"} height={size ? size : "16"} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0074 3.75446L7.77218 6.82264C1.56422 8.89877 1.56422 12.284 7.77218 14.3499L10.5131 15.2601L11.4233 18.001C13.4892 24.209 16.8847 24.209 18.9506 18.001L22.029 8.77605C23.3994 4.634 21.1495 2.37377 17.0074 3.75446ZM17.3347 9.25673L13.4483 13.1635C13.2949 13.317 13.1006 13.3885 12.9063 13.3885C12.712 13.3885 12.5176 13.317 12.3642 13.1635C12.0676 12.867 12.0676 12.376 12.3642 12.0795L16.2506 8.17264C16.5472 7.87605 17.0381 7.87605 17.3347 8.17264C17.6313 8.46923 17.6313 8.96014 17.3347 9.25673Z" fill="white" />
        </svg>

    )
}

export default SendIcon