import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import Loading from './Loading'
import { useNavigate } from 'react-router-dom'
import { Store } from '../Context/StateStore'
import Chart from './ChartBar'
import { Modal } from 'react-bootstrap'
import ProjectPad from '../SVG/ProjectPad'
import { toast } from 'react-toastify'
import ADminDas2 from './ADminDas2'
import AdminDas3 from './AdminDas3'

const AdminDash = () => {
  let [editingDep, setEditing] = useState()
  let { setactive, allDetails, apicall, getCurrentDate } = useContext(Store);

  let [task, settask] = useState({
    task_name: '',
    priority: null,
    remarks: '',
    due_date: '',
    created_by: JSON.parse(sessionStorage.getItem('dasid'))
  })
  const [editobj, setEditobj] = useState({
    id: null,
    name: null,
    type: null,

  })
  let [show, setshow] = useState(false)
  const handleChangedept = (e) => {
    let { name, value } = e.target
    setEditobj((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  let { employeeUnderUser, department, position, setDepartments, fetchDepartments, employee } = useContext(Store)
  let [showDepartment, setshowDepartment] = useState()
  let [changethings, setchangethings] = useState()
  let [obj, setobj] = useState({
    position: '',
    department_name: ''
  })
  let addDepartment = () => {
    console.log(obj);

    axios.post(`${hostName}/api/DisplayDepartments/`, {
      department_name: obj.department_name,
      position: obj.position
    }).then((response) => {
      console.log(response.data);
      fetchDepartments()
      setobj({
        position: '',
        department_name: ''
      })
    }).catch((error) => {
      console.log(error);
    })
  }


  let handleChange = (e) => {
    setobj((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  let handleChange2 = (e) => {
    let { name, value } = e.target
    if (name == 'priority' && Number(value) > 10) {
      // alert('hellow')
      value = 10
    }
    if (name == 'priority' && Number(value) < 0) {
      // alert('hellow')
      value = ''
    }
    if (name == 'due_date' && value < getCurrentDate()) {
      value = getCurrentDate()
    }
    settask((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  let navigate = useNavigate()
  useEffect(() => {
    employeeUnderUser()
    fetchDepartments()
  }, [])
  let handleUpdate = () => {
    if (editobj.type == 'dept') {
      axios.put(`${hostName}/api/DisplayDepartments/`, editobj).then((response) => {
        console.log(response.data);
        setEditing(false)
      }).catch((error) => {
        console.log(error);
      })
    }
    if (editobj.type == 'posi') {
      axios.patch(`${hostName}/api/DisplayDepartments/`, editobj).then((response) => {
        console.log(response.data);
        setEditing(false)

      }).catch((error) => {
        console.log(error);
      })
    }
    fetchDepartments()
  }
  let submitTask = () => {
    axios.post(`${hostName}/api2/ProjectCreationByEmployee/${JSON.parse(sessionStorage.getItem('dasid'))}/`, task).then((response) => {
      toast.success('Project Has been Created', {
        position: 'top-center',
        theme: 'colored',
        autoClose: 3000
      })
      setshow(false)
      apicall()
    }).catch((error) => {
      console.log(error);
    })
  }
  return (
    <div>


      {employee != undefined ? <>
        <div className='row '>

          <main className='col-md-8 '>
            {/* <section style={{ backgroundColor: '#FDEDEE' }} className=' rounded-lg flex-wrap flex ' >

              <div onClick={() => setshow(true)} className='w-full sm:w-1/4 cursor-pointer transition duration-500 btngrd hover:text-white rounded-lg h-52 flex justify-center items-center text-violet-900 fw-semibold '>
                <div className='flex flex-col gap-3 justify-between h-full py-12 items-center'>
                  <ProjectPad size={36} />
                  <p className='text-xl'> Project </p>
                </div>
              </div>
              <div onClick={() => { setshowDepartment(true); setchangethings('department') }} className='w-full sm:w-1/4 cursor-pointer transition duration-500 btngrd hover:text-white rounded-lg h-52 flex justify-center items-center text-violet-900 fw-semibold '>
                <div className='flex flex-col gap-3 justify-between h-full py-12 items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-person-square" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                  </svg>

                  <p className='text-xl'> Department </p>
                </div>
              </div>
              <div onClick={() => navigate('/registeration')} className='w-full sm:w-1/4 cursor-pointer transition duration-500 btngrd hover:text-white rounded-lg h-52 flex justify-center items-center text-violet-900 fw-semibold '>
                <div className='flex flex-col gap-3 justify-between h-full py-12 items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi m-auto bi-person-bounding-box" viewBox="0 0 16 16">
                    <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  </svg>
                  <p className='text-xl'> Member </p>
                </div>
              </div>
              <div onClick={() => { setshowDepartment(true); setchangethings('position') }} className='w-full sm:w-1/4 cursor-pointer transition duration-500 btngrd hover:text-white rounded-lg h-52 flex justify-center items-center text-violet-900 fw-semibold '>
                <div className='flex flex-col gap-3 justify-between h-full py-12 items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-layout-text-window-reverse" viewBox="0 0 16 16">
                    <path d="M13 6.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5m0 3a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5m-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                    <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zM2 1a1 1 0 0 0-1 1v1h14V2a1 1 0 0 0-1-1zM1 4v10a1 1 0 0 0 1 1h2V4zm4 0v11h9a1 1 0 0 0 1-1V4z" />
                  </svg> <p className='text-xl'> Role </p>
                </div>
              </div>
            </section> */}
            <ADminDas2 />
          </main>
          <main className='col-md-4 min-h-[40vh]  '>
            <div className='bg-white rounded-lg h-full w-full'>
              <AdminDas3 />
            </div>
          </main>

        </div>

        <div className='flex flex-wrap gap-4 my-5 justify-around   '>
          {/* User creation */}
          {
            showDepartment && <Modal centered show={showDepartment} onHide={() => setshowDepartment(false)} >
              <Modal.Header closeButton>
                Add the New {changethings == 'department' ? "Department" : 'Role'}
              </Modal.Header>
              <Modal.Body>
                Existed {changethings == 'department' ? "Department" : 'Role'}
                <div className={`flex flex-wrap justify-around `}>
                  {
                    department != undefined && changethings == 'department' ? department.map((x) => {
                      return (
                        <div onClick={() => {
                          setEditing(true); setEditobj({
                            id: x.id,
                            name: x.department_name,
                            type: 'dept'
                          }); setshowDepartment(false)
                        }} className='bg-slate-100 px-2 m-1 p-1 rounded-xl'>
                          {x.department_name}

                        </div>
                      )
                    })
                      : position.map((x) => {
                        return (
                          <div onClick={() => {
                            setEditing(true); setEditobj({
                              id: x.id,
                              name: x.position,
                              type: 'posi'
                            }); setshowDepartment(false)
                          }} className='bg-slate-100 px-2 m-1 p-1 rounded-xl'>
                            {x.position}
                          </div>
                        )
                      })}
                </div>
                <hr />
                <div className={`my-2 text-center ${changethings == 'department' ? "" : 'd-none'} `}>
                  Enter the Department name :
                  <input type="text" name='department_name' value={obj.department_name}
                    className='border-1 mx-1 outline-none my-2 p-2' placeholder="Technical Team"
                    onChange={handleChange} />
                </div>
                <div className={`my-2 text-center ${changethings == 'department' ? "d-none" : ''} `}>
                  Enter the Role name :
                  <input type="text" name='position' value={obj.position}
                    className='border-1 mx-1 outline-none my-2 p-2' placeholder="UI/Ux developer"
                    onChange={handleChange} />
                </div>
              </Modal.Body>
              <button onClick={addDepartment} className='ms-auto p-2 px-3 bg-green-600 rounded text-white m-3 w-fit'>Add {changethings == 'department' ? "Department" : "Position"}</button>
            </Modal>
          }
        </div>

      </> : <Loading />
      }
      {
        editingDep && <Modal centered onHide={() => setEditing(false)} show={editingDep} >
          <Modal.Body>
            <div>
              Change the Name :
              <input type="text" value={editobj.name} name='name' onChange={handleChangedept} className='outline-none rounded p-2 px-3 border-1 ' />
              <button onClick={handleUpdate} className='p-2 px-3 bg-slate-500 text-white '>Update</button>
            </div>
          </Modal.Body>


        </Modal>
      }
      {
        show && <Modal centered className='' show={show} onHide={() => setshow(false)} >
          <Modal.Header className='p-3 ' closeButton >
            Adding the Task
          </Modal.Header>
          <Modal.Body className='p-3'>
            <form action="" className='flex justify-between flex-wrap'>
              <div className='sm:w-[45%] '>
                Task Name : <span className='text-red-600'>*</span>
                <input type="text" onChange={handleChange2} name='task_name' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none' placeholder="Enter the task name " />
              </div>
              <div className='sm:w-[45%] '>
                Priority :
                <input type="number" value={task.priority} onChange={handleChange2} name='priority'
                  className=' border-1 p-2 block my-2 rounded w-32 nospin bg-slate-50 outline-none'
                  placeholder="Enter in 1- 10 " />
              </div>
              <div className='sm:w-[45%] '>
                Remarks :
                <textarea type="text" onChange={handleChange2} name='remarks' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none'
                  placeholder="Enter the remarks " />

              </div>
              <div className='sm:w-[45%] '>
                Due Date :
                <input type="date" value={task.due_date} onChange={handleChange2} name='due_date' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none' placeholder="Enter the task name " />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={submitTask} className='p-2 px-3 bg-green-600 text-white rounded'>Create</button>
            <button onClick={() => setshow(false)} className='p-2 px-3 bg-red-600 text-white rounded'>Close</button>

          </Modal.Footer>

        </Modal>
      }
    </div>
  )
}

export default AdminDash
