import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { hostName } from "../App";
import { useNavigate } from "react-router-dom";
import { Store } from "../Context/StateStore";
import Loading from "../Component/Loading";
import { toast } from "react-toastify";

const Registration = () => {
  let { employeeUnderUser, employee } = useContext(Store)
  let navigate = useNavigate()
  let admin = JSON.parse(sessionStorage.getItem('status'))
  useEffect(() => {
    employeeUnderUser()
    console.log(employee);
  }, [])
  // State variables to store form data and dropdown options
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    department: "",
    position: "",
    reporting_to: "",
    status: "",
    profile_pic: null, // Changed to null to indicate no file selected
  });

  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [status, setstatus] = useState([])

  // Fetch departments and positions from backend on component mount
  useEffect(() => {
    fetchDepartments();
  }, []);
  let [loadingbutton,setloadingbutton]=useState(false)
  // Function to fetch departments and positions from backend
  const fetchDepartments = () => {
    axios
      .get(`${hostName}/api/DisplayDepartments/`)
      .then((response) => {
        setDepartments(response.data.Departments);
        setPositions(response.data.Positions);
        setstatus(response.data)
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the form data to the backend
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("department", formData.department);
    formDataToSend.append("position", formData.position);
    // formDataToSend.append("profile_pic", formData.profile_pic);
    formDataToSend.append("status", formData.status);
    formDataToSend.append("reporting_to", formData.reporting_to)
    if(formData.name!=''&& formData.email!=''&& formData.password!=''&& formData.position!=''&& formData.department!=''&& 
    formData.status!=''&& formData.reporting_to!=''
    )
       {setloadingbutton(true)
    axios.post(
        `${hostName}/api/EmployeeRegistration/`,
        formDataToSend
      )
      .then((response) => {
        console.log("Form submitted successfully:", response.data);
        // Optionally, you can reset the form fields here
        setloadingbutton(false)
        setFormData({
          name: "",
          email: "",
          password: "",
          department: "",
          position: "",
          reporting_to: "",
          status: "",
          profile_pic: null, // Reset to null
        });
        setImagePreview("");
        alert("Employee Saved successfully")
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setloadingbutton(false)

      });}
      else{
        toast.error('Enter all the feild')
      }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Function to handle file input change and generate image preview
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, profile_pic: file });

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview("");
    }
  };

  return (
    <div className="min-h-[100vh]  flex">
      {
        admin != 'admin' ? <div className="h-[100vh] w-full flex flex-col justify-center items-center ">
          <h4 className="text-center mx-auto w-fit">Not Authorized </h4>
          <h4 className="text-center mx-auto w-fit">Admin can only Autherizable </h4>

        </div> : employee != undefined ? <>

          <div style={{ backgroundColor: "#4c3575" }}
            className="shadow col-md-7 col-11 text-white col-lg-6 col-xl-5 p-3 poppins rounded-2xl  m-auto">
            <div onClick={()=>navigate('/dashboard')} 
            className="p-3 w-fit cursor-pointer ms-auto rounded-full ">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </div>
            <img className="mx-auto my-3" width={150} src={require('../Assests/meridawebsitelogowhite.png')} alt="Company logo" />
            <h2 className="text-center">Employee Registration Form</h2>
            <form className="flex flex-wrap gap-3 p-3" encType="multipart/form-data">
              <div className="w-full md:w-[45%] ">
                <label htmlFor="name">Name: <span className="text-red-500">*</span> </label>
                <input
                  className="my-2 w-full block text-slate-950 outline-none rounded p-2 border-slate-400 border-1"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter the Name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full  md:w-[45%] ">
                <label htmlFor="email">Email:  <span className="text-red-500">*</span></label>
                <input
                  className="my-2 w-full  block outline-none text-slate-950 rounded p-2 border-slate-400 border-1"

                  type="email"
                  id="email"
                  name="email" placeholder="Enter the Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full  md:w-[45%] ">
                <label htmlFor="password">Password:  <span className="text-red-500">*</span></label>
                <input
                  className="my-2  w-full  block outline-none text-slate-950 rounded p-2 border-slate-400 border-1"
                  placeholder="Enter the password"
                  type="text"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
              {/*Status as  Position  */}
              <div className="w-full  md:w-[45%] ">
                Position <span className="text-red-500">*</span>
                <select onChange={handleInputChange} value={formData.status} className="my-2 w-full block outline-none text-slate-950 rounded p-2 border-slate-400 border-1"

                  name="status" id="">
                  <option value="">Select Position</option>
                  <option value="admin">Admin</option>
                  <option value="head">Head</option>
                  <option value="manager">Manager</option>
                  <option value="team_leader">Team Lead</option>
                  <option value="employee">Executive</option>
                </select>
              </div>
              <div className="w-full  md:w-[45%] ">
                <label htmlFor="department">Department:  <span className="text-red-500">*</span></label>
                <select
                  className="my-2 w-full   block outline-none text-slate-950 rounded p-2 border-slate-400 border-1"

                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                >
                  <option value="">Select Department</option>
                  {departments.map((dept) => (
                    <option key={dept.id} value={dept.id}>
                      {dept.department_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full  md:w-[45%] ">
                <label htmlFor="position">Role:  <span className="text-red-500">*</span></label>
                <select
                  className="my-2 w-100  block outline-none text-slate-950 rounded p-2 border-slate-400 border-1"

                  id="position"
                  name="position"
                  value={formData.position}
                  onChange={handleInputChange}
                >
                  <option value="">Select Role</option>
                  {positions.map((pos) => (
                    <option key={pos.id} value={pos.id}>
                      {pos.position}
                    </option>
                  ))}
                </select>
              </div>
              {/* Reporting to */}
              <div className="w-full  md:w-[45%] ">
                <label htmlFor="position">Reporting to :  <span className="text-red-500">*</span></label>
                <select
                  className="my-2 w-100  block outline-none text-slate-950 rounded p-2 border-slate-400 border-1"

                  id=""
                  name="reporting_to"
                  value={formData.reporting_to}
                  onChange={handleInputChange}
                >
                  <option value="">Select Reporting to</option>
                  {employee.map((pos) => (
                    <option key={pos.name} value={pos.pk}>
                      {pos.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="mx-auto ">
                <label htmlFor="profile_pic">Profile Picture :  <span className="text-red-500">*</span></label>
                <input
                  className="my-2 block outline-none rounded p-2 border-slate-400 border-1"

                  type="file"
                  id="profile_pic"
                  name="profile_pic"
                  onChange={handleFileInputChange}
                />
              </div> */}

              {imagePreview && (
                <div>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </div>
              )}
            </form>
            <button onClick={handleSubmit} className="mx-auto mt-3 block w-filt p-2 px-3 bg-green-500 text-white rounded"
            > { loadingbutton ?"Loading...": "Submit"} </button>
          </div>
        </> : <Loading />
      }
    </div>
  );
};

export default Registration;
