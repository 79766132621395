import React, { useContext, useEffect, useState } from 'react'
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";
import axios from 'axios';
import { hostName } from '../App';
import { Store } from '../Context/StateStore';
import StickyNotes from './StickyNotes';
import { toast } from 'react-toastify';
import EmployeeAnalysis from './AdminDas2/EmployeeAnalysis';

const Chart = () => {
  const { currentDate, apicall, user, setAllDetails, allDetails } = useContext(Store)

  const [allemp, setAllEmp] = useState([])
  const [taskDetails, setTaskDetails] = useState({
    completedOnTime: [],
    completedAfterTime: [],
    pending: [],
    overDue: [],
    totalProjects: [],
  })
  const [MtaskDetails, MsetTaskDetails] = useState({
    completedOnTime: [],
    completedAfterTime: [],
    pending: [],
    overDue: [],
    totalProjects: [],
  })
  const leadsbycourse = {
    labels: allemp,
    datasets: [
      {
        label: 'Total Task',
        data: taskDetails.totalProjects,
        fill: false,
        backgroundColor: 'rgb(105,105,105)',
        tension: 1,
        barThickness: 8,
      },
      {
        label: 'Completed On time',
        data: taskDetails.completedOnTime,
        fill: false,
        backgroundColor: 'rgb(132,204,23)',
        tension: 1,
        barThickness: 8,
      },
      {
        label: 'Pending',
        data: taskDetails.pending,
        fill: false,
        backgroundColor: 'rgb(255,164,39)',
        tension: 1,
        barThickness: 8,
      },
      {
        label: 'Over Due ',
        data: taskDetails.overDue,
        fill: false,
        backgroundColor: 'rgb(255,112,114)',
        tension: 1,
        barThickness: 8,
      },
      {
        label: 'Completed after Time ',
        data: taskDetails.completedAfterTime,
        fill: true,
        backgroundColor: 'rgb(49,154,255)',
        tension: 1,
        barThickness: 8,
      },
    ],
  };

  let [counts, setcounts] = useState()
  let getStrategy = async () => {
    await axios.get(`${hostName}/api/CountsOfProjects/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
      console.log(response.data);
      setcounts([response.data.percentage.pending_tasks,
      response.data.percentage.completed_on_time,
      response.data.percentage.completed_after_time,
      response.data.percentage.over_due])
    }).catch((error) => {
      console.log(error);
    })
  }
  useEffect(() => {
    axios.get(`${hostName}/api2/admingraph/${JSON.parse(sessionStorage.getItem('dasid'))}/`).then((response) => {
      console.log(response.data);
      setAllEmp(response.data.map((x) => x.name))
      setTaskDetails((prev) => ({
        overDue: response.data.map((x) => x.over_due_projects),
        completedAfterTime: response.data.map((x) => x.completed_after_time_projects),
        pending: response.data.map((x) => x.pending_projects),
        totalProjects: response.data.map((x) => x.total_projects),
        completedOnTime: response.data.map((x) => x.completed_on_time_projects)
      }))
      console.log(response.data.map((x) => x.total_projects));
    }).catch((error) => {
      console.log(error);
    })
    getStrategy()
    if (allDetails) {
      // alert('hellow')
      setcounts([
        // allDetails.all_tasks.length,
        allDetails.count.pending_tasks,
        allDetails.count.completed_on_time,
        allDetails.count.completed_after_time,
        allDetails.count.over_due])
    }
    // apicall()
  }, [allDetails])
  const leadsdata = {
    labels: ['Pending Task', 'Completed on time', 'Completed After time', 'Over due'],
    datasets: [
      {
        label: '',
        data: counts,
        fill: true,
        backgroundColor: ['rgb(255,164,39)', 'rgb(132,204,23)', 'rgb(49,154,255)', 'rgb(255,112,114)'],
        tension: 0.1,
      },
    ],
  };

  let status = JSON.parse(sessionStorage.getItem('status'))
  return (
    <div className='row my-4 justify-between'>
      {
        status != 'employee' &&
        <section className='col-md-8 p-3'>

          <div style={{ backgroundColor: 'rgb(253,243,248)' }}
            className='min-h-[50vh]  p-3 rounded-2xl my-3 mx-auto'>
            <h4 className='text-center '>Team Analysis </h4>
            <div
              className='h-[50vh] mx-auto w-[45vw] scrollbar bg overflow-scroll '>
              <Bar className='' data={leadsbycourse}></Bar>
            </div>
          </div>
        </section>
      }
      {
        status != 'admin' && allDetails && leadsdata &&
        <section className='  p-3 col-md-4 '>
          <div style={{ backgroundColor: 'rgb(253,243,248)' }}
            className='min-h-[50vh] my-4 rounded-lg p-3'>
            <h4 className='text-center'>Personal Analysis </h4>
            <Doughnut data={leadsdata}></Doughnut>
          </div>
        </section>
      }
      {
        status == 'admin' && allDetails && leadsdata &&
        <section className='  p-3 col-md-4 '>
          <div style={{ backgroundColor: 'rgb(253,243,248)' }}
            className='min-h-[50vh] my-4 rounded-lg p-3'>
            <EmployeeAnalysis />
            
          </div>
        </section>
      }
      <section className='col-md-8  col-lg-6 col-cl-4 '>
        <StickyNotes />

      </section>
    </div>
  )
}

export default Chart
