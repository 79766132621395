import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../Context/StateStore';
import axios from 'axios';
import { hostName } from '../App';
import Loading from '../Component/Loading';
import TableRow from '../Component/TableRow';
import ProjectTable from '../Component/ProjectTable';
import AdminTaskListing from '../Component/AdminTaskListing';
import { useNavigate, useParams } from 'react-router-dom';
import DownloadButton from '../Component/DownloadButton';

const PendingList = () => {
  let { id } = useParams()
  let [selectedId, setSelectedId] = useState(id)
  let { setactive, apiCallTaskChangesCall,user, pendingSubTask, setpendingSubTask } = useContext(Store);
  let [pid, setPid] = useState()
  let status = JSON.parse(sessionStorage.getItem(`status`))
  let navigate = useNavigate()
  useEffect(() => {
    setactive("pending");
    if (pid) {
      axios.get(`${hostName}/api/SubTaskList/${pid}`).then((response) => {
        setpendingSubTask(response.data)
      }).catch((error) => { console.log(error); })
    }
  }, [pid]);
  const [data, setdata] = useState()
  useEffect(() => {
    axios.get(`${hostName}/api/PendingProjects/pending/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
      setdata(response.data)
      console.log(response.data);
    }).catch((error) => { console.log(error); })
  }, [])
  const updateObj = (index, newValue) => {
    const newObj = [...pendingSubTask];
    console.log(newValue);
    console.log(newObj[0].remarks);
    newObj[index].remarks = newValue;
    setpendingSubTask(newObj);
  };
  useEffect(() => {
    let sid=(sessionStorage.getItem('selectedEmp'))
    if(sid!='all'){
      setSelectedId(sid)
    }
  }, [])
  return (
    <div className="poppins p-3">
      <div className='flex justify-between flex-wrap'>
        <h3>Pending Task List </h3>
        <button onClick={() => { navigate('/dashboard/overdue'); }} className='bg-red-300 text-white shadow-sm px-3 rounded-lg '>
          Overdue task
        </button>
      </div>
      {/* <DownloadButton data={data} name={`Project_report`} /> */}
      { data != undefined && data != undefined&& user&&
         (status.toLowerCase() == 'employee' 
         || status.toLowerCase() == 'team_leader') &&
         <DownloadButton data={data} 
         name={`Pending_${user.name}`} />}
      {data != undefined && pid == null && status != 'admin' ?
        <ProjectTable setPid={setPid} data={data} type='own' /> : ''
      }
      {pid != null && <button className={`p-2 px-3 bg-slate-900 ms-auto flex text-white rounded bg-opacity-70 `} onClick={() => setPid(null)}> Back </button>}
      {pendingSubTask != undefined && pid != null ?
        <TableRow obj={pendingSubTask} type='details' updateObj={updateObj} />
        : ''
      }
      {/* Add more rows as needed */}
      {status.toLowerCase() != 'employee' && status.toLowerCase() != 'team_leader' &&
        <div className={` `}>
          <AdminTaskListing selectedid={selectedId} mas='pending' />
        </div>
      }
    </div>
  )
}

export default PendingList