import React, { useContext, useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import EmployeeDrop from '../EmployeeDrop';
import { Store } from '../../Context/StateStore';
import axios from 'axios';
import { hostName } from '../../App';

const EmployeeAnalysis = () => {
    let [counts, setcounts] = useState()
    let [selectedEmployee, setSelectedEmployee] = useState()
    let { employee, employeeUnderUser } = useContext(Store)
    const leadsdata = {
        labels: ['Pending Task', 'Completed on time', 'Completed After time', 'Over due'],
        datasets: [
            {
                label: '',
                data: counts,
                fill: true,
                backgroundColor: ['rgb(255,164,39)', 'rgb(132,204,23)', 'rgb(49,154,255)', 'rgb(255,112,114)'],
                tension: 0.1,
            },
        ],
    };
    useEffect(() => {
        employeeUnderUser()
    }, [])
    let getStrategy = async () => {
        await axios.get(`${hostName}/api/CountsOfProjects/${selectedEmployee}`).then((response) => {
            console.log(response.data);
            setcounts([response.data.percentage.pending_tasks,
            response.data.percentage.completed_on_time,
            response.data.percentage.completed_after_time,
            response.data.percentage.over_due])
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        if (selectedEmployee)
            getStrategy()
    }, [selectedEmployee])
    useEffect(() => {
        if (employee)
            setSelectedEmployee([...employee].reverse()[0].employee_id)
    }, [employee])
    return (
        <div>
            <section className='flex justify-between items-center ' >
                <h5> Employee analysis </h5>
                <select name="" id="" value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}
                    className='outline-none w-32 rounded p-1 text-sm ' >
                    <option value="">Select </option>
                    {
                        employee != undefined && employee.map((x) => {
                            return (
                                <option value={x.employee_id}>{x.name} ({x.position && x.position.position}) </option>
                            )
                        })
                    }
                </select>
            </section>
            <Doughnut data={leadsdata}></Doughnut>
        </div>
    )
}

export default EmployeeAnalysis