import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import { json, useNavigate } from 'react-router-dom'
import Loading from '../Component/Loading'
import { Modal, Spinner } from 'react-bootstrap'
import StateStore, { Store } from '../Context/StateStore'
import Das from './Das'
import { CalendarIcon } from '@mui/x-date-pickers'
import CalenderView from '../SVG/CalenderView'
import ProfileIcon from '../SVG/ProfileIcon'
import { toast } from 'react-toastify'

const EmployeeDetails = () => {
    let [userId, setUserId] = useState(JSON.parse(sessionStorage.getItem('dasid')))
    let status = JSON.parse(sessionStorage.getItem('dasid'))
    let navigate = useNavigate()
    let [userDetails, setUserDetails] = useState([])
    let [apicall, setapicall] = useState(true)
    let { setactive, getCurrentDate } = useContext(Store)
    let [showoperations, setShowoperations] = useState(false)
    let [selectedEmp, setSelectedEmp] = useState({
        name: '',
        email: '',
        blocked_status: null,
        employee_id: null,
        des: ''
    })
    let [show, setshow] = useState(false)
    let [task, settask] = useState({
        task_name: '',
        priority: null,
        remarks: '',
        due_date: '',
        created_by: JSON.parse(sessionStorage.getItem('dasid'))
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        if (name == 'priority' && Number(value) > 10) {
            // alert('hellow')
            value = 10
        }
        if (name == 'priority' && Number(value) < 0) {
            // alert('hellow')
            value = ''
        }
        if (name == 'due_date' && value < getCurrentDate()) {
            value = getCurrentDate()
        }
        settask((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [showDas, setShowDas] = useState(false)
    let getEmployeDetails = () => {
        axios.get(`${hostName}/api/ReportingEmployees/${userId}`).then((response) => {
            console.log(response.data);
            setUserDetails(response.data)
            setapicall(true)
        }).catch((error) => {
            console.log(error);
            setapicall(true)
        })
    }
    useEffect(() => {
        setapicall(false)
        setactive('emp')
        if (userId) {
            getEmployeDetails()
        }
    }, [userId])
    let blockEmplye = (id) => {
        axios.post(`${hostName}/api2/BlockUser/${JSON.parse(sessionStorage.getItem('dasid'))}/`, {
            empid: selectedEmp.employee_id
        }).then((response) => {
            console.log(response.data);
            setShowoperations(false)
            toast.error(`Emplyoee has been ${response.data}`, {
                position: 'top-center',
                autoClose: 3000,
                theme: 'colored'
            })
            getEmployeDetails()
        }).catch((error) => {
            console.log(error);
        })
    }
    let submitTask = () => {
        axios.post(`${hostName}/api2/CreateProjectAssignProject/${JSON.parse(sessionStorage.getItem('dasid'))}/`, {
            empid: selectedEmp.employee_id,
            ...task
        }).then((response) => {
            console.log(response.data);
            setshow(false)
            toast.success(`Project has been added to ${selectedEmp.name}`, {
                autoClose: 3000,
                theme: 'colored',
                position: 'top-center'
            })

        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <div>
            <div className={` table-responsive w-full border-slate-600 table-bordered `}>
                {
                    userDetails && apicall ? <table className="w-full border-1 min-w-[700px] ">
                        <tr>
                            <th><p className={` px-2  `}> SI.No</p></th>
                            <th><p className="px-2 "> User Name </p></th>
                            <th><p className="px-2 ">Email</p></th>
                            {/* <th><p className="px-2 "> Phone</p></th> */}
                            <th><p className="px-2 ">Role</p></th>
                            <th><p className="px-2 "> Department</p></th>
                            <th><p className="px-2 ">Position</p></th>
                            <th><p className="px-2 "> Action </p></th>
                        </tr>
                        {userDetails.map((obj, index) => {
                            console.log(obj);
                            return (
                                <tr className={`${obj.blocked_status ? 'bg-red-50' : index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"} h-[50px] `}>
                                    <td className=''><p className="px-2  "> {index + 1}</p></td>
                                    <td onClick={() => {
                                        if (obj.reporters == true)
                                            setUserId(obj.employee_id)
                                    }} className={`${obj.reporters == true ? 'cursor-pointer bg-slate-300' : ''} `}>
                                        <p className="px-2 "> {obj.name}</p>
                                    </td>
                                    <td><p className={`${obj.blocked_status?'d-none':''} px-2 `}>{obj.email}</p>
                                    <input type="text" value={obj.email} className={`${obj.blocked_status?'':'d-none'} bg-transparent px-2`} />
                                    </td>
                                    {/* <td><p className="px-2 "> Phone</p></td> */}
                                    <td><p className="px-2 ">{obj.position.position}</p></td>
                                    <td><p className="px-2 "> {obj.department.department_name}</p></td>
                                    <td><p className="px-2 ">{obj.status} </p></td>
                                    <td className='bg-blue-400 text-white w-fit' onClick={() => {
                                        setShowoperations(true); setSelectedEmp({
                                            name: obj.name,
                                            email: obj.email,
                                            employee_id: obj.employee_id,
                                            des: obj.position.position,
                                            blocked_status: obj.blocked_status
                                        })
                                    }}><button className='px-2 ' > Click here </button></td>

                                </tr>
                            )
                        })
                        }
                    </table>
                        : !apicall ? <div className='h-[50vh] flex '>
                            <Spinner className='m-auto' animation='grow' variant="dark" />
                        </div> : <div>
                            No Employee Yet Reporting
                        </div>
                }
            </div>
            {
                showDas && <Modal show={showDas} onHide={() => setShowDas(false)} centered size='xl'>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Das empDes={selectedEmp.des} emailId={selectedEmp.email} empName={selectedEmp.name} />
                </Modal>
            }
            {
                showoperations && selectedEmp &&
                <Modal show={showoperations} centered size='xl' onHide={() => { setShowoperations(false) }} >
                    <Modal.Header closeButton>
                        Action on the {selectedEmp.name}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='p-3 flex gap-3 justify-around  flex-wrap'>
                            <section className='flex  items-center flex-col'>
                                <button onClick={() => {
                                    setShowDas(true);
                                    setShowoperations(false)
                                }} className='w-fit h-fit text-center p-4 bg-slate-100 rounded-full'>
                                    <CalenderView />
                                </button>
                                <p className='w-36 text-center '>Show Das</p>
                            </section>
                            <section className='flex items-center flex-col'>
                                <button onClick={() => {
                                    setshow(true);
                                    setShowoperations(false)
                                }} className='w-fit h-fit text-center p-4 bg-blue-100 rounded-full'>
                                    <CalenderView />
                                </button>
                                <p className='w-36 text-center'> Create Task </p>
                            </section>
                            <section className='flex items-center flex-col'>
                                <button onClick={() => {
                                    navigate(`/dashboard/pendingTask/${selectedEmp.employee_id}`)
                                }} className='w-fit h-fit text-center p-4 bg-yellow-100 rounded-full'>
                                    <CalenderView />
                                </button>
                                <p className='w-36 text-center'>View Pending Task</p>
                            </section>
                            <section className='flex items-center flex-col'>
                                <button onClick={() => {
                                    navigate(`/dashboard/overdue/${selectedEmp.employee_id}`)
                                }} className='w-fit h-fit text-center p-4 bg-orange-100 rounded-full'>
                                    <CalenderView />
                                </button>
                                <p className='w-36 text-center'>View Overdue Task</p>
                            </section>
                            <section className='flex items-center flex-col'>
                                <button onClick={() => {
                                    navigate(`/dashboard/completedOntime/${selectedEmp.employee_id}`)
                                }} className='w-fit h-fit text-center p-4 bg-green-100 rounded-full'>
                                    <CalenderView />
                                </button>
                                <p className='w-36 text-center'>View Completed OnTime Task</p>
                            </section>
                            <section className='flex items-center flex-col'>
                                <button onClick={() => {
                                    navigate(`/dashboard/completedAfterTime/${selectedEmp.employee_id}`)
                                }} className='w-fit h-fit text-center p-4 bg-amber-100 rounded-full'>
                                    <CalenderView />
                                </button>
                                <p className='w-36 text-center'>View Completed After Task</p>
                            </section>
                            <section className='flex items-center flex-col'>
                                <button onClick={() => blockEmplye()} className='w-fit h-fit text-center p-4 bg-red-100 rounded-full'>
                                    <ProfileIcon />
                                </button>
                                <p className='w-36 text-center'> {selectedEmp.blocked_status ? "Unblock" : "Block"} Employee </p>
                            </section>
                        </div>


                    </Modal.Body>
                </Modal>
            }
            {
                show && <Modal centered className='' show={show} onHide={() => setshow(false)} >
                    <Modal.Header className='p-3 ' closeButton >
                        Adding the Task to {selectedEmp.name}
                    </Modal.Header>
                    <Modal.Body className='p-3'>
                        <form action="" className='flex justify-between flex-wrap'>
                            <div className='sm:w-[45%] '>
                                Task Name :
                                <input type="text" onChange={handleChange} name='task_name' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none' placeholder="Enter the task name " />
                            </div>
                            <div className='sm:w-[45%] '>
                                Priority :
                                <input type="number" value={task.priority} onChange={handleChange} name='priority'
                                    className=' border-1 p-2 block my-2 rounded w-32 nospin bg-slate-50 outline-none'
                                    placeholder="Enter in 1- 10 " />
                            </div>
                            <div className='sm:w-[45%] '>
                                Remarks :
                                <textarea type="text" onChange={handleChange} name='remarks' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none'
                                    placeholder="Enter the remarks " />
                            </div>
                            <div className='sm:w-[45%] '>
                                Due Date :
                                <input type="date" value={task.due_date} onChange={handleChange} name='due_date' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none' placeholder="Enter the task name " />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={submitTask} className='p-2 px-3 bg-green-600 text-white rounded'>Create</button>
                        <button onClick={() => setshow(false)} className='p-2 px-3 bg-red-600 text-white rounded'>Close</button>

                    </Modal.Footer>

                </Modal>
            }

        </div>
    )
}

export default EmployeeDetails
