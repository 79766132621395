import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import { Store } from '../Context/StateStore'
import Loading from '../Component/Loading'
import DepDas from '../Component/DepDas'
import PlusIcon from '../SVG/PlusIcon'
import { Modal } from 'react-bootstrap'
import RupeeIcon from '../SVG/RupeeIcon'

const Das = (props) => {
  let { emailId, empName, empDes, empdepartment } = props
  let { setactive, calenderDate, setCalenderDate, setUser,
    getDas, setdas, currentDate, das, apicall, user, getCurrentDate } = useContext(Store)
  let status = JSON.parse(sessionStorage.getItem('status'))
  let [otherDepartment, setOtherDepartment] = useState()
  const [departments, setDepartments] = useState([]);
  const fetchDepartments = () => {
    axios
      .get(`${hostName}/api/DisplayDepartments/`)
      .then((response) => {
        setDepartments(response.data.Departments);
        console.log(response.data.Departments);
        console.log(response.data.Departments.find((obj) => obj.id == empdepartment).department_name);
        setOtherDepartment(response.data.Departments.find((obj) => obj.id == empdepartment).department_name)
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  };
  useEffect(() => {
    if (empdepartment) {
      // alert(empdepartment)
      fetchDepartments()
    }
  }, [empdepartment])
  let todayDate = calenderDate
  let [billingModel, setBillingModel] = useState('')
  let [email, setEmail] = useState(JSON.parse(sessionStorage.getItem('email')))
  let [billing_obj, setbilling_obj] = useState({
    billing_projected: 0,
    billing_acheived: 0,
    collection_projected: 0,
    collection_acheived: 0,
  })

  let getBillingDetails = (date) => {
    console.log(date);
    axios.put(`${hostName}/api/BillingView/${JSON.parse(sessionStorage.getItem('dasid'))}/`, {
      date: date
    }).then((response) => {
      response.data.length > 0 ? setbilling_obj(response.data[0]) : setbilling_obj({
        billing_projected: 0,
        billing_acheived: 0,
        collection_projected: 0,
        collection_acheived: 0,
      })
      console.log(response.data);
    }).catch((error) => { console.log(error); })
  }
  useEffect(() => {
    setactive(emailId ? 'emp' : 'das')
    if (emailId) {
      setEmail(emailId)
      getDas(calenderDate, emailId)
    }
    else {
      getDas(calenderDate, email)

    }
    getBillingDetails(calenderDate)
  }, [])

  const billing = () => {
    console.log(billing_obj);
    axios.post(`${hostName}/api/BillingView/${JSON.parse(sessionStorage.getItem('dasid'))}/`, billing_obj).then((response) => {
      console.log(response.data);
      getDas(calenderDate, email)
      alert('Changes Made')
      setBillingModel('')
    }).catch((error) => {
      console.log(error);
    })
  }
  const updateObj = (index, newValue) => {
    const newObj = [...das];
    let newindex = newObj.findIndex((obj) => obj.id == index)
    newObj[newindex].remarks2 = newValue;
    setdas(newObj);
  };
  let handleBilling = (e) => {
    setbilling_obj((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  return (
    <div className='p-sm-4 p-2'>{

      user != undefined && das != undefined && currentDate != undefined ? <>

        <input type="date" value={calenderDate}
          onChange={(e) => {
            setCalenderDate(e.target.value);
            console.log(e.target.value);
            getDas(e.target.value, email)
            getBillingDetails(e.target.value)
          }}
          className='p-2 px-3 outline-none bg-slate-100 rounded ms-auto flex mb-4' />
        <div className='flex flex-col flex-sm-row gap-3 justify-between'>
          {user && <div>
            <p> Employee Name :  {empName ? empName : user.full_name}</p>
            <p>Designation : {empDes ? empDes : user.Position && user.Position} </p>
          </div>}


          <div>

            <p>Date : {calenderDate}</p>
            <p>Reporting Time : 9:30 - 6:30</p>
          </div>
        </div>

        {user && console.log(user, "user")}
        {/* Billing cards */}
        {((user.status == 'manager' &&
          ((user.Department) == 'Sales' ||
            (user.Department) == 'Business Development')) ||
          (user.Department) == 'Sales' ||
          (user.Department) == 'Business Development' ||
          (otherDepartment && otherDepartment == 'Sales') ||
          (otherDepartment && otherDepartment == 'Business Development')) &&

          <main className=' flex flex-wrap gap-3 justify-around'>
            <article className='dascolbg border-2 p-3 w-[15rem] py-4 rounded-lg items-center flex gap-2'>
              <button onClick={() => {
                setBillingModel('billing_projected')
              }} disabled={getCurrentDate() != calenderDate || emailId}
                className='rounded-full p-2 text-violet-600 border-pink-100 border-4 '>
                <PlusIcon size={20} /> </button>
              <div className=' text-sm '>
                Billing Projected
                <p className='mb-0 flex gap- text-xl items-center fw-bold'>
                  <RupeeIcon />
                  {billing_obj && billing_obj.billing_projected}
                </p>
              </div>
            </article>
            {/* Billing achieved */}
            <article className='dascolbg border-2 p-3 w-[15rem] py-4 rounded-lg items-center flex gap-2'>
              <button onClick={() => {
                setBillingModel('billing_acheived')

              }} disabled={getCurrentDate() != calenderDate || emailId}
                className='rounded-full p-2 text-violet-600 border-pink-100 border-4 '>
                <PlusIcon size={20} /> </button>
              <div className=' text-sm '>
                Billing Achieved
                <p className='mb-0 flex gap- text-xl items-center fw-bold'>
                  <RupeeIcon />
                  {billing_obj && billing_obj.billing_acheived}
                </p>
              </div>
            </article>
            {/* Collection Projected */}
            <article className='dascolbg border-2 p-3 w-[15rem] py-4 rounded-lg items-center flex gap-2'>
              <button onClick={() => {
                setBillingModel('collection_projected')

              }} disabled={getCurrentDate() != calenderDate || emailId}
                className='rounded-full p-2 text-violet-600 border-pink-100 border-4 '><PlusIcon size={20} /> </button>
              <div className=' text-sm '>
                Collection Projected
                <p className='mb-0 flex gap- text-xl items-center fw-bold'>
                  <RupeeIcon />
                  {billing_obj && billing_obj.collection_projected}
                </p>
              </div>
            </article>
            {/* Collection achieved */}
            <article className='dascolbg border-2 p-3 w-[15rem] py-4 rounded-lg items-center flex gap-2'>
              <button onClick={() => {
                setBillingModel('collection_acheived')
              }} disabled={getCurrentDate() != calenderDate || emailId}
                className='rounded-full p-2 text-violet-600 border-pink-100 border-4 '><PlusIcon size={20} /> </button>
              <div className=' text-sm '>
                Collection  Achieved
                <p className='mb-0 flex gap- text-xl items-center fw-bold'>
                  <RupeeIcon />
                  {billing_obj && billing_obj.collection_acheived}
                </p>
              </div>
            </article>

          </main>
        }
        {/* Table */}
        <div className='my-10 '>
          {

            <DepDas emailId={emailId} updateObj={updateObj} todayDate={todayDate} dasSheet={das} />
          }
        </div>


        {/* Add button to add the Das */}



      </> : <Loading />
    }
      {billingModel != '' &&
        <Modal show={billingModel != ''} onHide={() => setBillingModel('')} centered  >
          <Modal.Header closeButton>
            {billingModel.toUpperCase().replace(/_/g, " ")}
          </Modal.Header>
          <Modal.Body>
            <input onKeyDown={(e) => {
              if (e.key == 'Enter') {
                billing()
              }
            }} onChange={(e) => { handleBilling(e) }} type="number" name={`${billingModel}`} placeholder='Amount' className='mx-auto flex my-2 outline-none border-2 p-2 rounded' />
            <button disabled={emailId} onClick={billing} className='px-4 flex p-2 bg-violet-600 text-white my-2 rounded mx-auto '>Add </button>
          </Modal.Body>

        </Modal>
      }
    </div>
  )
}

export default Das
