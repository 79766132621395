import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { hostName, hrapi } from '../App'
import { useNavigate } from 'react-router-dom'


export const Store = createContext(null)
const StateStore = (props) => {
    const [activepage, setactive] = useState('dashboard')
    const [show, setshow] = useState(false)
    const currentDate = (new Date()).toString().slice(0, 16)
    const [allDetails, setAllDetails] = useState()
    let [particularProjectTask, setParticularProjectTask] = useState()
    const status = JSON.parse(sessionStorage.getItem('status'))
    const [user, setUser] = useState()
    const [notification, setnotification] = useState()
    let getNotification = () => {
        axios.get(`${hostName}/api/DisplayPushNotifications/${JSON.parse(sessionStorage.getItem('dasid'))}/`).then((response) => {
            console.log(response.data);
            setnotification(response.data)

        }).catch((error) => {
            console.log(error);
        })
    }
    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    let [employee, setEmployees] = useState()
    const [calenderDate, setCalenderDate] = useState(getCurrentDate())
    let [das, setdas] = useState();
    const [pendingSubTask, setpendingSubTask] = useState()
    const [completedTask, setCompletedtask] = useState()
    let [department, setDepartments] = useState()
    let [position, setPositions] = useState()
    async function apicall() {
        if (JSON.parse(sessionStorage.getItem('dasid'))) {
            await axios.get(`${hrapi}/root/ems/EmployeeProfile/${JSON.parse(sessionStorage.getItem('dasid'))}/`).then((response) => {
                setUser(response.data.EmployeeInformation)
                console.log(response.data.EmployeeInformation);
            }).catch((error) => {
                console.log(error);
            })
            await axios.get(`${hostName}/api/ParticularUserTaskss/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
                console.log(response.data);
                setAllDetails(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    const getParticulatTaskList = (id) => {
        console.log(id);
        axios.get(`${hostName}/api/SubTaskList/${id}/`).then((response) => {
            console.log(response.data);
            setParticularProjectTask(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    const fetchDepartments = () => {
        axios
            .get(`${hostName}/api/DisplayDepartments/`)
            .then((response) => {
                setDepartments(response.data.Departments);
                setPositions(response.data.Positions);
                console.log(response.data);
            })
            .catch((error) => {
                console.error("Error fetching departments:", error);
            });
    };
    let employeeUnderUser = () => {
        axios.get(`${hostName}/api/AdminEmployees/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
            setEmployees(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let getDas = (date, email) => {
        console.log({ date });
        if (JSON.parse(sessionStorage.getItem('email'))) {
            axios.post(`${hostName}/api/dasview/${date}/${email}/`,).then((response) => {
                setdas(response.data)
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    let [taskloading, setTaskLoading] = useState(false)
    let apiCallTaskChangesCall = (e, pid) => {
        console.log(pid);
        let bool = e.target.value == 'completed' ? true : false
        axios.put(`${hostName}/api/ParticularUserTaskss/${JSON.parse(sessionStorage.getItem('dasid'))}/`,
            { id: pid, status: bool }).then((response) => {
                // getParticulatTaskList(pid)
                setTaskLoading(null)
                window.location.reload()
                alert(`Task changed to ${e.target.value == 'completed' ? 'completed' : 'Pending'}`)
                apicall()
            }).catch((error) => {
                setTaskLoading(null)

                // alert('error')
                console.log(error);
            })
    }
    //Storage to distribute
    useEffect(() => {
        apicall()
        getDas(calenderDate, JSON.parse(sessionStorage.getItem('email')))
    }, [(sessionStorage.getItem('dasid'))])
    const storeValue = { notification, setnotification, getNotification, getParticulatTaskList, taskloading, setTaskLoading, particularProjectTask, setParticularProjectTask, getCurrentDate, position, department, setDepartments, fetchDepartments, status, calenderDate, employeeUnderUser, employee, setEmployees, setCalenderDate, apiCallTaskChangesCall, setdas, das, getDas, completedTask, setCompletedtask, allDetails, pendingSubTask, setpendingSubTask, apicall, show, setshow, setUser, user, activepage, setactive, currentDate }
    return (
        <Store.Provider value={storeValue} >
            {props.children}
        </Store.Provider>
    )
}

export default StateStore