import React, { useContext, useEffect, useRef, useState } from 'react'
import SendIcon from '../../SVG/SendIcon'
import { Store } from '../../Context/StateStore'
import axios from 'axios'
import { hostName } from '../../App'

const Rightside = (props) => {
    let { user } = useContext(Store)
    let { sendUser } = props
    const toAddress = {
        name: 'Jerold'
    }
    const [message, setMessage] = useState([])
    let [typedmessage, setTypemessage] = useState('')
    let getmessage = () => {
        if (user && sendUser) {
            console.log(user.employee_id + " " + sendUser.employee_id);
            axios.get(`${hostName}/api2/MessageView/chat/?sender=${JSON.parse(sessionStorage.getItem('dasid'))}&receiver=${sendUser.employee_id}`).then((response) => {
                console.log(response.data);
                setMessage(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    useEffect(() => {
        console.log(user, "user");
        getmessage()
        setTypemessage('')
    }, [user, sendUser])
    function convertDateTime(dateTimeString) {
        // Parse the input datetime string
        const date = new Date(dateTimeString);

        // Extract the components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = String(date.getFullYear()).slice(2); // Get last two digits of the year

        // Get the hours and minutes in local time
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Determine AM/PM and convert to 12-hour format
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'

        // Format the time part
        const formattedTime = `${hours}:${minutes} ${ampm}`;

        // Combine and return the formatted date and time
        return `${day}/${month}/${year} ${formattedTime}`;
    }
    let postMessagesend = () => {
        console.log(user, "user");
        axios.post(`${hostName}/api2/uploadmessage/`, {
            sender: JSON.parse(sessionStorage.getItem('daspk')),
            receiver: sendUser.pk,
            message: typedmessage,
        }).then((response) => {
            console.log(response.data);
            getmessage()
            setTypemessage('')
        }).catch((error) => {
            console.log(error);
        })
    }
    let scrollContainerRef = useRef()
    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    }, [message]);
    return (
        <div className='w-full h-[100%] p-0 col-md-8 flex flex-col col-xl-9 '>
            {sendUser && <header className='flex rounded-tr-xl   h-[12%] border-b-2 bg-white w-full p-3 gap-3'>
                <img src={sendUser.profile_pic ? sendUser.profile_pic : require('../../Assests/profilepic.webp')} className='w-14 h-14 rounded-full object-cover ' alt="ProfileDemo" />
                <div>
                    <h6>{sendUser.name} </h6>
                    <p> {sendUser.department.department_name} ({sendUser.position.position}) </p>
                </div>
            </header>}
            <body ref={scrollContainerRef} className='h-[80%] py-2 w-full px-2 bg-transparent scrollbar overflow-y-scroll  '>
                {
                    message && user && message.map((obj) => {
                        return (
                            <div className={`w-fit flex flex-col my-1 gap-1 ${obj.sender.pk == JSON.parse(sessionStorage.getItem('daspk')) ? 'ms-auto  ' : ''} `}>
                                <p className={`rounded-xl p-2 m-0 bg-slate-50 ${obj.sender.pk == JSON.parse(sessionStorage.getItem('daspk')) ? 'rounded-tr-none flex bg-violet-600 text-white' : 'rounded-tl-none '}  `}>
                                    {obj.message}
                                </p>
                                <span className={`${obj.sender.pk == JSON.parse(sessionStorage.getItem('daspk')) ? 'ms-auto ' : ''}  text-xs `}>
                                    {convertDateTime(obj.created_at)}</span>
                            </div>)
                    })
                }
            </body>
            <footer className='w-full h-[8%] rounded-none sticky mt-auto border-1 rounded-br-xl bottom-1 bg-white py-1 items-center  flex px-3'>
                <input value={typedmessage} onKeyUp={(e) => {
                    if (e.key == 'Enter') {
                        postMessagesend()
                    }
                }} onChange={(e) => setTypemessage(e.target.value)} type="text" className='w-full h-full outline-none text-lg'
                    placeholder='Send your message..' />
                <button onClick={postMessagesend} className='bg-violet-600 p-2  rounded text-white '>
                    <SendIcon size={28} />
                </button>
            </footer>
        </div>
    )
}

export default Rightside