import React, { useContext, useEffect, useState } from 'react'
import ProjectPad from '../SVG/ProjectPad'
import { Store } from '../Context/StateStore';
import { hostName } from '../App';
import axios from 'axios';
import ProjectList from './AdminDas2/ProjectList';
import Members from './AdminDas2/Members';
import PendingList from './AdminDas2/PendingList';

const ADminDas2 = () => {
    let [activeSet, setActiveSet] = useState('project')
    let { setactive, allDetails, apicall, getCurrentDate } = useContext(Store);
    let [allEmpytask, setAllEmpTask] = useState()
    let [allEmpy, setAllEmpy] = useState()
    let getallTask = () => {
        axios.get(`${hostName}/api/all_tasks/`).then((response) => {
            setAllEmpTask(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let [pendingTask, setPendingTask] = useState()
    let getPendingProjects = () => {
        axios.get(`${hostName}/api/CreatedProjectsView/${JSON.parse(sessionStorage.getItem('dasid'))}/pending/`).then((response) => {
            setPendingTask(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let getAllEmpy = () => {
        axios.get(`${hostName}/api2/SendEmployee/${JSON.parse(sessionStorage.getItem('dasid'))}/`).then((response) => {
            console.log(response.data);
            setAllEmpy(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getallTask()
        getAllEmpy()
        getPendingProjects()
    }, [])
    return (
        <div className='row'>
            <main className='col-md-4 ps-0 p-3 px-4'>
                <section onClick={() => {
                    setActiveSet('project')
                }} style={{ backgroundColor: '#FDEDEE' }}
                    className={`${activeSet == 'project' && 'btngrd2 text-white '} cursor-pointer 
                transition duration-500 btngrd hover:text-white rounded-lg h-52 flex justify-center items-center text-violet-900 fw-semibold `}>
                    <div className='flex flex-col gap-3 justify-between h-full py-12 items-center'>
                        <h4>{allEmpytask != undefined && allEmpytask.length} </h4>
                        <p className='text-xl'> Tasks </p>
                    </div>
                </section>
                <section onClick={() => {
                    setActiveSet('pending')
                }} style={{ backgroundColor: '#FDEDEE' }} className={`${activeSet == 'pending' && 'btngrd2 text-white '} my-2 cursor-pointer transition duration-500 btngrd hover:text-white rounded-lg h-52 flex justify-center items-center
                 text-violet-900 fw-semibold `}>
                    <div className=' flex flex-col gap-3 justify-between h-full py-12 items-center'>
                        <h4>{pendingTask != undefined && pendingTask.length} </h4>

                        <p className='text-xl'> Pending Task</p>
                    </div>
                </section>
                <section onClick={() => setActiveSet('member')} style={{ backgroundColor: '#FDEDEE' }}
                    className={`${activeSet == 'member' && 'btngrd2 text-white '}dascolbg cursor-pointer transition duration-500 btngrd hover:text-white rounded-lg h-52 flex justify-center items-center text-violet-900 
                 fw-semibold `}>
                    <div className='flex flex-col gap-3 justify-between h-full py-12 items-center'>
                        <h4>{allEmpy != undefined && allEmpy.length} </h4>
                        <p className='text-xl'> Members </p>
                    </div>
                </section>
            </main>
            {/* Right side */}
            <main className='col-md-8 min-h-[40vh] p-3'>
                <section className='h-full w-full rounded-lg p-3 dascolbg'>
                    {allEmpytask && activeSet == 'project' && <ProjectList data={allEmpytask} />}
                    {allEmpy && activeSet == 'member' && <Members data={allEmpy} />}
                    {allEmpy && activeSet == 'pending' && <PendingList data={pendingTask} />}

                </section>
            </main>
        </div>
    )
}

export default ADminDas2