import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const DoughnutAnalysis = (props) => {
    let { data } = props
    console.log(data);
    delete data.completed_tasks
    const chartData = {
        labels: Object.keys(data),  
        datasets: [
            {
                data: Object.values(data),
                backgroundColor: [ '#00D9C0', '#546FFF', '#D80032','#FF9F40',],
                hoverBackgroundColor: [ '#01D9C0', '#546FFF', '#D80032','#FF9F40',]
            }
        ]
    };
    const options = {
        cutoutPercentage: 70, // To create the "donut" effect
        maintainAspectRatio: false,
        legend: {
            display: false,
            
        },

        
    };
    return (
        <div className="chart-container" style={{ position: "relative", width: "100%", height: "400px" }}>
            <Doughnut data={chartData} 
            options={options} />
        </div>
    )
}

export default DoughnutAnalysis