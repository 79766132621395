import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../Context/StateStore';
import axios from 'axios';
import { hostName } from '../App';
import TableRow from '../Component/TableRow';
import Loading from '../Component/Loading';
import ProjectTable from '../Component/ProjectTable';
import AdminTaskListing from '../Component/AdminTaskListing';
import DownloadButton from '../Component/DownloadButton';
const ComAfterTime = () => {
  let { setactive, user, completedTask, setCompletedtask } = useContext(Store);
  let [data, setdata] = useState();
  let [pid, setPid] = useState()
  let status = JSON.parse(sessionStorage.getItem('status'))
  let [cdata, setcdata] = useState()
  useEffect(() => {
    if (pid) {
      axios.get(`${hostName}/api/SubTaskList/${pid}`).then((response) => {
        setcdata(response.data)
      }).catch((error) => { console.log(error); })
    }
  }, [pid])
  useEffect(() => {
    setactive("completed");
    axios.get(`${hostName}/api/PendingProjects/completed_after_time/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
      console.log(response.data);
      setdata(response.data)
    }).catch((error) => { console.log(error); })
  }, []);
  const updateObj = (index, newValue) => {
    const newObj = [...cdata];
    newObj[index].remarks = newValue;
    setcdata(newObj);
  };
  return (
    <div className="poppins p-3">
      {
        data != undefined && data != null ? <>

          <h3> Task List Completed After time </h3>
          {data != undefined && data != undefined && user &&
            (status.toLowerCase() == 'employee'
              || status.toLowerCase() == 'team_leader') &&
            <DownloadButton data={data}
              name={`Completed_after_time_${user.name}`} />}
          {data != undefined && pid == null && status != 'admin' ?
            <ProjectTable setPid={setPid} data={data} type='own' /> : ''
          }
          {cdata != undefined && pid != null ?
            <TableRow obj={cdata} updateObj={updateObj} />
            : ''
          }

        </> : <Loading />
      }
      {status.toLowerCase() != 'employee' && status.toLowerCase() != 'team_leader' && <div className={` `}>
        <AdminTaskListing mas='completed_after_time' />

      </div>}
    </div>
  )
}

export default ComAfterTime