// TimePicker.js
import React from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

const TimePicker = ({ value, onChange }) => {
  return (
    <Flatpickr
      value={value}
      onChange={onChange}
      options={{
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      }}
    />
  );
};

export default TimePicker;
