import React, { useContext, useEffect, useState } from 'react'
import SearchIcon from '../../SVG/SearchIcon'
import { Store } from '../../Context/StateStore'

const LeftSide = (props) => {
    let { allEmpy,setsender,sendUser } = props
    let [filterEmpy,setFilterEmp]=useState(allEmpy)
    let { user } = useContext(Store)
    let [filterword,setFilterWord]=useState()
    let filterFunc=()=>{
          let filterdata=allEmpy.filter((obj)=>obj.name.toLowerCase().indexOf(filterword.toLowerCase())!=-1)
          setFilterEmp(filterdata)
    }
    useEffect(() => {

    }, [])
    return (
        <div className='p-3 col-md-4 col-xl-3 rounded-s-2xl bg-white'>
            <div className='rounded border-1 justify-between flex p-2 items-center '>
                <input type="text" className='outline-none ' onChange={(e)=>setFilterWord(e.target.value)} 
                onKeyDown={(e)=>{
                    if(e.key=="Enter"){
                        filterFunc()
                    }
                }} placeholder='Search' />
                <div onClick={filterFunc}><SearchIcon /> </div>
            </div>
            <div className='bg-yellow-00 h-[78vh] scrollbar overflow-y-scroll '>

                {/* Profile card */}
                {filterEmpy && filterEmpy.map((obj) => (
                    <>
                    {console.log(obj,'user')
                    }
                        <section onClick={()=>{
                            setsender(obj)
                        }} className={`flex ${obj.pk==sendUser.pk ? 'bg-slate-300':'hover:bg-slate-100 '  } rounded-2xl cursor-pointer
                            gap-2 items-center p-2 my-1 `}>
                            <img src={obj.profile_pic? obj.profile_pic :require('../../Assests/profilepic.webp')} className='w-12 h-12 object-cover rounded-full '
                                alt="Profile image" />
                            <div className=''>
                                <p className='mb-0 text-lg '>{obj.name}</p>
                                <p className='mb-0 text-xs'> {obj.department.department_name+" "+obj.status} <span>({obj.position.position}) </span> </p>
                            </div>
                        </section>
                        <hr className='m-0 border-slate-400 '/>
                    </>
                ))}
            </div>
        </div>
    )
}

export default LeftSide