import React, { useContext, useEffect, useRef, useState } from 'react'
import { Store } from '../Context/StateStore'
import { hostName } from '../App'
import axios from 'axios'
import Loading from '../Component/Loading'
import { useNavigate } from 'react-router-dom'
import AdminDash from '../Component/AdminDash'
import Chart from '../Component/ChartBar'
import BasketIcon from '../SVG/BasketIcon'
import OverdueRight from '../Component/AdminDas2/OverdueRight'

const DashBoardContent = () => {
    const { currentDate, apicall, user, setAllDetails, allDetails } = useContext(Store)
    console.log(allDetails);
    let status=JSON.parse(sessionStorage.getItem('status'))
    console.log(status,'hellow');
    // alert('hellow')
    let navigate = useNavigate()
    let audioRef = useRef()
    let [showing, setshowing] = useState('self')
    let { setactive, setUser } = useContext(Store)
    let [data, setdata] = useState()
    let [teamPending, setTeamPending] = useState()
    let [selfPending, setSelfPending] = useState()
    let [selfOverdue, setSelfOverdue] = useState()

    let [teamOverdue, setTeamOverDue] = useState()
    let [activeState, setActiveState] = useState('pending')
    let [strategy, setStrategy] = useState()
    let getStrategy = async () => {
        // alert(JSON.parse(sessionStorage.getItem('dasid')))
        let mid = JSON.parse(sessionStorage.getItem('dasid'))
        if (mid)
            await axios.get(`${hostName}/api/CountsOfProjects/${mid}`).then((response) => {
                console.log(response.data, "count");
                setStrategy(response.data)
            }).catch((error) => {
                console.log(error, "count");
            })
    }
    let getTeamPending = () => {
        if (JSON.parse(sessionStorage.getItem('dasid')))
            axios.get(`${hostName}/api/CreatedProjectsView/${JSON.parse(sessionStorage.getItem('dasid'))}/pending/`).then((response) => {
                setTeamPending(response.data)
                console.log("team", response.data);
            }).catch((error) => {
                console.log(error, "err");
            })
    }
    let getTeamOverdue = () => {
        axios.get(`${hostName}/api/CreatedProjectsView/${JSON.parse(sessionStorage.getItem('dasid'))}/over_due/`).then((response) => {
            setTeamOverDue(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        setactive('dashboard')
        apicall()
        getTeamOverdue()
        getTeamPending()
        getStrategy()
        sessionStorage.setItem('selectedEmp', 'all')
        // axios.get(`${hostName}/api/ParticularUserTasks`).then((response) => {
        //     console.log(response.data);
        //     setAllDetails(response.data)
        // }).catch((error) => {
        //     console.log(error);
        // })

        axios.get(`${hostName}/api/EmployeeDetailsL/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
            setUser(response.data)
        }).catch((error) => { console.log(error); })
    }, [])
    useEffect(() => {
        axios.get(`${hostName}/api/PendingProjects/over_due/${JSON.parse(sessionStorage.getItem('dasid'))} `).then((response) => {
            setSelfOverdue(response.data)
            console.log(response.data);
        }).catch((error) => console.log(error))
        axios.get(`${hostName}/api/PendingProjects/pending/${JSON.parse(sessionStorage.getItem('dasid'))} `).then((response) => {
            setSelfPending(response.data)
            console.log(response.data);
        }).catch((error) => console.log(error))
        if (activeState == 'overdue') {
            axios.get(`${hostName}/api/PendingProjects/over_due/${JSON.parse(sessionStorage.getItem('dasid'))} `).then((response) => {
                setdata(response.data)
                console.log(response.data);
            }).catch((error) => console.log(error))
        }
        if (activeState == 'pending') {
            axios.get(`${hostName}/api/PendingProjects/pending/${JSON.parse(sessionStorage.getItem('dasid'))} `).then((response) => {
                setdata(response.data)
                console.log(response.data);
            }).catch((error) => console.log(error))
        }
    }, [activeState])
    return (
        <div className='poppins p-3'>
            {
                allDetails != undefined && user != undefined ? <>

                    {/* <div className='flex px-3 justify-between items-center'>
                        <div className="w-fit border-1 border-slate-300 p-3 shadow rounded-xl ">
                            Welcome {user.name}
                            <p>  {currentDate} </p>
                        </div>
                        <div className='p-3 rounded border-1 shadow '>
                            {user.status != undefined ? user.status.toUpperCase() : ''}
                        </div>
                    </div> */}
                    {

                        (status
                            &&
                            status.toLowerCase() == 'admin') ? <AdminDash />
                            :
                            <>
                                <div className='row'>
                                    <main className='flex flex-wrap h-fit col-md-8 gap-3 justify-between my-3'>
                                        <div onClick={() => {
                                            navigate(`/dashboard/tasklist/`)
                                        }} className='w-[10rem] sm:w-[15rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 '>
                                            <h6 className='text-lg text-center'> Task List</h6>
                                            <div className=' flex gap-3 my-3     w-fit m-auto'>
                                                <p className='mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ' ><BasketIcon /> </p>
                                                <h6 className='text-2xl sm:text-5xl text-center'> {allDetails.all_tasks.length} </h6>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            navigate(`/dashboard/pendingTask/`)
                                        }} className='w-[10rem] sm:w-[15rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 '>
                                            <h6 className='text-lg text-center'> Pending Task</h6>
                                            <div className=' flex gap-3 my-3     w-fit m-auto'>
                                                <p className='mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ' ><BasketIcon /> </p>
                                                <h6 className='text-2xl sm:text-5xl text-center'> {strategy && strategy.count.pending_tasks} </h6>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            navigate(`/dashboard/overdue/`)
                                        }} className='w-[10rem] sm:w-[15rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 '>
                                            <h6 className='text-lg text-center'>Overdue Task </h6>
                                            <div className=' flex gap-3 my-3     w-fit m-auto'>
                                                <p className='mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ' ><BasketIcon /> </p>
                                                <h6 className='text-2xl sm:text-5xl text-center'> {strategy && strategy.count.over_due} </h6>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            navigate(`/dashboard/completedTask/`)
                                        }} className='w-[10rem] sm:w-[15rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 '>
                                            <h6 className='text-lg text-center'> Completed Task</h6>
                                            <div className=' flex gap-3 my-3     w-fit m-auto'>
                                                <p className='mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ' ><BasketIcon /> </p>
                                                <h6 className='text-2xl sm:text-5xl text-center'> {strategy && strategy.count.completed_tasks} </h6>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            navigate(`/dashboard/completedOntime/`)
                                        }} className='w-[10rem] sm:w-[15rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 '>
                                            <h6 className='text-lg text-center'> Completed OnTime</h6>
                                            <div className=' flex gap-3 my-3     w-fit m-auto'>
                                                <p className='mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ' ><BasketIcon /> </p>
                                                <h6 className='text-2xl sm:text-5xl text-center'> {strategy && strategy.count.completed_on_time} </h6>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            navigate(`/dashboard/completedAfterTime/`)
                                        }} className='w-[10rem] sm:w-[15rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 '>
                                            <h6 className='text-lg text-center'> Completed AfterTime </h6>
                                            <div className=' flex gap-3 my-3     w-fit m-auto'>
                                                <p className='mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ' ><BasketIcon /> </p>
                                                <h6 className='text-2xl sm:text-5xl text-center'> {strategy && strategy.count.completed_after_time} </h6>
                                            </div>
                                        </div>
                                    </main>
                                    <main className='col-md-4 relative bg-white p-3 rounded-lg'>

                                        <div className='flex justify-around'>
                                            <button onClick={() => setActiveState('pending')}
                                                className={`${activeState == 'pending' ? 'text-orange-600' : 'text-slate-600'} fw-semibold`}>
                                                <span className='badge bg-red-500 -top-2 relative'> {showing == 'self' && selfPending && selfPending.length} {showing == 'team' && teamPending && teamPending.length} </span>
                                                Pending {showing == 'self' && (selfPending && selfPending.length == 0 ? '😍' :
                                                    selfPending && selfPending.length > 0 && selfPending.length <= 2 ? '😊' : '🥲')}
                                                {showing == 'team' && (teamPending && teamPending.length == 0 ? '😍' :
                                                    teamPending && teamPending.length > 0 && teamPending.length < 2 ? '😊' : '🥲')}
                                            </button>
                                            <button onClick={() => setActiveState('overdue')}
                                                className={`${activeState == 'overdue' ? 'text-orange-600' : 'text-slate-600'} fw-semibold`}>
                                                <span className='badge bg-red-500 -top-2 relative'> {showing == 'self' && selfOverdue && selfOverdue.length} {showing == 'team' && teamOverdue && teamOverdue.length} </span>

                                                Overdue {showing == 'self' && (selfOverdue &&
                                                    selfOverdue.length == 0 ? '😍' :
                                                    selfOverdue && selfOverdue.length > 0 && selfOverdue.length <= 2 ? '😊' : '🥲')}
                                                {showing == 'team' && (teamOverdue && teamOverdue.length == 0 ? '😍' :
                                                    teamOverdue && teamOverdue.length > 0 && teamOverdue.length < 2 ? '😊' : '🥲')}
                                            </button>
                                        </div>
                                        <hr />
                                        <div className='scrollbar2 overflow-y-scroll h-[20vh] '>
                                            {showing == 'self' &&
                                                data && [...data].reverse().map((x) => {
                                                    console.log(x);
                                                    return (
                                                        <div className='py-2 p-2 border-2 my-2 '>
                                                            <p className=' text-slate-900 fw-medium '> Task Name :
                                                                <span className=' text-slate-500'> {x.task_name}</span> </p>
                                                            <div className='flex items-center justify-between'>
                                                                <p className=' text-slate-900 fw-medium mb-0'> Deadline :
                                                                    <span className=' text-slate-500'> {x.due_date.slice(0, 10)} </span>
                                                                </p>
                                                                {x.assigned_to && <button onClick={() => navigate(`/dashboard/chat/${x.assigned_to.employee_id}`)} className='p-1 px-3 bg-blue-500 rounded text-white '>
                                                                    chat
                                                                </button>
                                                                }
                                                            </div>
                                                        </div>)
                                                })}
                                            {showing == 'team' && activeState == 'pending' &&
                                                teamPending && [...teamPending].reverse().map((x) => {
                                                    console.log(x);
                                                    return (
                                                        <div className='py-2 p-2 border-2 my-2 '>
                                                            <p className=' text-slate-900 fw-medium mb-1'>

                                                                Task Name :
                                                                <span className=' text-slate-500'> {x.task_name}</span> </p>
                                                            <p className=' text-slate-900 fw-medium mb-1'> Assigned to :
                                                                <span className=' text-slate-500'> {x.assigned_to.name}</span> </p>
                                                            <div className='flex items-center justify-between'>
                                                                <p className=' text-slate-900 fw-medium mb-1'> Deadline :
                                                                    <span className=' text-slate-500'> {x.due_date.slice(0, 10)} </span>
                                                                </p>
                                                                {x.assigned_to && <button onClick={() => navigate(`/dashboard/chat/${x.assigned_to.employee_id}`)} className='p-1 px-3 bg-blue-500 rounded text-white '>
                                                                    chat
                                                                </button>
                                                                }
                                                            </div>
                                                        </div>)
                                                })}
                                            {showing == 'team' && activeState == 'overdue' &&
                                                teamOverdue && [...teamOverdue].reverse().map((x) => {
                                                    console.log(x);
                                                    return (
                                                        <div className='py-2 p-2 border-2 my-2 '>
                                                            <p className=' text-slate-900 fw-medium mb-1'> Task Name :
                                                                <span className=' text-slate-500'> {x.task_name}</span> </p>
                                                            <p className=' text-slate-900 fw-medium mb-1'> Assigned to :
                                                                <span className=' text-slate-500'> {x.assigned_to.name}</span> </p>
                                                            <div className='flex items-center justify-between'>
                                                                <p className=' text-slate-900 fw-medium mb-1'> Deadline :
                                                                    <span className=' text-slate-500'> {x.due_date.slice(0, 10)} </span>
                                                                </p>
                                                                {x.assigned_to && <button onClick={() => navigate(`/dashboard/chat/${x.assigned_to.employee_id}`)} className='p-1 px-3 bg-blue-500 rounded text-white '>
                                                                    chat
                                                                </button>
                                                                }
                                                            </div>
                                                        </div>)
                                                })}
                                        </div>
                                        {(JSON.parse(sessionStorage.getItem('status')) == 'manager' ||
                                            JSON.parse(sessionStorage.getItem('status')) == "team_leader") &&
                                            <div className='flex items-center mx-auto w-fit '>
                                                Self
                                                <button onClick={() => {
                                                    if (showing == 'self') {
                                                        setshowing('team')
                                                    }
                                                    else {
                                                        setshowing('self')
                                                    }
                                                }} className={`w-14 h-7 border-2 mx-2 ${showing == 'self' ? 'bg-yellow-400' : 'bg-blue-400'}  transi relative shadow-sm rounded-full p-1`}>
                                                    <p className={`w-4 h-4 m-0 absolute top-1 ${showing == 'self' ? "translate-x-[1.1px] " : ' translate-x-[30px] '} bg-slate-50 transi rounded-full shadow-sm `}> </p>
                                                </button>
                                                Team
                                            </div>}
                                    </main>
                                </div>
                                {/* <button onClick={()=>{
                                audioRef.current.play()
                            }} className='p-2 px-3 bg-red-400'>
                                Audio
                            </button> */}
                                <audio src="../Assests/notify.wav" ref={audioRef} > </audio>
                            </>

                    }
                </> :
                    <Loading />
                // "hellow"

            }

            <Chart />
        </div>
    )
}

export default DashBoardContent