import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/StateStore';
import LeftSide from './LeftSide';
import Rightside from './Rightside';
import axios from 'axios';
import { hostName } from '../../App';
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
const ChattingPage = () => {
  let { id } = useParams()
  let { setactive, completedTask, setCompletedtask } = useContext(Store);
  let user = JSON.parse(sessionStorage.getItem('dasid'))
  let [sendUser, setSendUser] = useState()
  let [employee, setEmployee] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
    setactive('chat')
    if (user) {
      axios.get(`${hostName}/api2/SendEmployee/${user}/`).then((response) => {
        console.log(response.data,"employee");
        setEmployee(response.data);
        if(response.data[0]) { setSendUser(response.data[0]);}
      }).catch((error) => {
        console.log(error);
      })
    }
  }, [user])
  useEffect(() => {
    if(id){
      setSendUser(employee.find((obj)=>obj.employee_id==id))
    }
  }, [id,employee])

  return (
    <div className={`h-[90vh] ${!sendUser && "flex"} p-4 py-2`}>
      {employee && sendUser ? <main className='h-full mx-auto shadow bg- rounded-2xl  row '>
        <LeftSide sendUser={sendUser} setsender={setSendUser} allEmpy={employee} />
        <Rightside sendUser={sendUser} />
      </main> :
        <Spinner className='m-auto flex' />
      }

    </div>
  )
}

export default ChattingPage