import axios, { all } from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import TableRow from './TableRow'
import ProjectTable from './ProjectTable'
import { Modal } from 'react-bootstrap'
import { Store } from '../Context/StateStore'
import EmployeeDrop from './EmployeeDrop'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import DownloadButton from './DownloadButton'

const AdminTaskListing = (props) => {
    let { id } = useParams()
    let { mas } = props
    let { getParticulatTaskList, particularProjectTask, setParticularProjectTask, getCurrentDate } = useContext(Store)
    let status = JSON.parse(sessionStorage.getItem('status'))
    let [allEmployeeTask, setAllEmployeTask] = useState()
    let [uid, setuid] = useState()
    let [pid, setPid] = useState()
    let [show, setshow] = useState()
    let [filterData, setFilterData] = useState()
    let [task, settask] = useState({
        task_name: '',
        priority: null,
        remarks: '',
        due_date: '',
        created_by: JSON.parse(sessionStorage.getItem('dasid'))
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        if (name == 'priority' && Number(value) > 10) {
            // alert('hellow')
            value = 10
        }
        if (name == 'priority' && Number(value) < 0) {
            // alert('hellow')
            value = ''
        }
        if (name == 'due_date' && value < getCurrentDate()) {
            value = getCurrentDate()
        }
        settask((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let { employeeUnderUser, department, position, setDepartments, fetchDepartments, employee } = useContext(Store)

    let navigate = useNavigate()
    let getMasData = () => {
        if (mas) {
            axios.get(`${hostName}/api/CreatedProjectsView/${JSON.parse(sessionStorage.getItem('dasid'))}/${mas}/`).then((response) => {
                setFilterData(response.data)

                // alert('hellow')
                setAllEmployeTask(response.data)
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            setFilterData(allEmployeeTask && allEmployeeTask.reverse())
        }
    }
    useEffect(() => {
        employeeUnderUser()
        fetchDepartments()
        if (mas) {
            console.log(mas);
            axios.get(`${hostName}/api/CreatedProjectsView/${JSON.parse(sessionStorage.getItem('dasid'))}/${mas}/`).then((response) => {
                setFilterData(response.data)
                setAllEmployeTask(response.data)
                console.log(response.data);
                console.log(mas);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            setFilterData(allEmployeeTask && allEmployeeTask.reverse())
        }
    }, [mas])
    let submitTask = (e) => {
        console.log(task);
        e.preventDefault()
        if (task.task_name != '') {
            if (sessionStorage.getItem('selectedEmp') != 'all') {
                axios.post(`${hostName}/api2/CreateProjectAssignProject/${JSON.parse(sessionStorage.getItem('dasid'))}/`, {
                    empid: sessionStorage.getItem('selectedEmp'),
                    ...task
                }).then((response) => {
                    console.log(response.data);
                    setshow(false)
                    toast.success(`Project has been added to `, {
                        autoClose: 3000,
                        theme: 'colored',
                        position: 'top-center'
                    })
                    if (status.toLowerCase() == 'admin' && mas == undefined) {
                        console.log("All the task");
                        getallTask()
                    }
                    else if (status.toLowerCase() == 'admin' && mas != undefined) {
                        getMasData()
                        console.log("Got it");
                    }
                    else {
                        console.log("I'M in the particular ");
                        getParticularreporting()
                        // getallTask()
                    }
                }).catch((error) => {
                    console.log(error);
                })
            }
            else {
                axios.post(`${hostName}/api/ProjectCreate/${JSON.parse(sessionStorage.getItem('email'))}/`, task).then((response) => {
                    console.log(response.data);
                    setshow(false)
                    alert('Task Added')
                    if (status.toLowerCase() == 'admin' && mas == undefined) {
                        console.log("All the task");
                        getallTask()
                    }
                    else if (status.toLowerCase() == 'admin' && mas != undefined) {
                        getMasData()
                        console.log("Got it");
                    }
                    else {
                        console.log("I'M in the particular ");
                        getParticularreporting()
                        // getallTask()
                    }
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
        else {
            toast.warning('Fill the feilds')
        }
    }
    let getallTask = () => {
        axios.get(`${hostName}/api/all_tasks/`).then((response) => {
            setAllEmployeTask(response.data)
            setFilterData(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let getParticularreporting = () => {
        if (!mas) {
            axios.get(`${hostName}/api/CreatedTasksByEmployee/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
                console.log("jellow", response.data);
                setAllEmployeTask(response.data)
                setFilterData(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    useEffect(() => {
        if (status.toLowerCase() == 'admin' && !mas)
            getallTask()
        else if (status.toLowerCase() != 'admin') {
            getParticularreporting()
            // getallTask()
        }
    }, [])
    let filterTaskOnAdmin = (name) => {
        console.log(name);
        let Filterarray = allEmployeeTask.filter((obj) => {
            console.log(obj.assigned_to);
            if (obj.assigned_to) {
                return obj.assigned_to.employee_id == name
            }
        })
        name == 'all' ? setFilterData(allEmployeeTask) : setFilterData(Filterarray)
        console.log(Filterarray);
    }
    const updateObj = (index, newValue) => {
        const newObj = [...particularProjectTask];
        newObj[index].remarks = newValue;
        setParticularProjectTask(newObj);
    };
    useEffect(() => {
        setuid(id)
        let sid = sessionStorage.getItem('selectedEmp')
        if (id && allEmployeeTask) {
            filterTaskOnAdmin(id)
        }
        if (sid && sid != 'all' && allEmployeeTask) {
            console.log(sid);
            filterTaskOnAdmin(sid)
        }
    }, [id, allEmployeeTask])
    let [docname, setDocName] = useState('')
    let generateName = () => {
        let text = 'All_task'
        let selectedid = sessionStorage.getItem('selectedEmp')
        console.log(selectedid);
        if (mas) {
            text = mas + "_task"
        }
        if (employee && selectedid != 'all') {
            let name = [...employee].find((obj) => obj.employee_id == selectedid).name.replace(/\s/g, "_")

            text = text + "_" + name
        }
        console.log(text);
        setDocName(text)
    }
    console.log(filterData);
    useEffect(() => {
        generateName()
    }, [employee, uid])
    return (

        <div>
            {allEmployeeTask != undefined && filterData != undefined ?
                <>
                    <section className='flex justify-between items-center ' >

                        <h6> Total tasks : {filterData.length} </h6>
                        <DownloadButton data={filterData}
                            name={docname} />
                    </section>
                    <EmployeeDrop uid={uid} filterfun={filterTaskOnAdmin} setid={setuid} />
                    {particularProjectTask == undefined || pid == null ?

                        <ProjectTable setshow={setshow} settask={settask} setPid={setPid} pid={pid} getallTask={getallTask}
                            getParticularreporting={getParticularreporting} data={filterData} /> : ''
                    }
                    {particularProjectTask != undefined && pid != null ?
                        <>
                            <hr />
                            <div className='flex my-3 flex-wrap justify-between'>
                                <p className={`${particularProjectTask[0] != undefined ? '' : 'd-none '} `}>Tasks under the {particularProjectTask[0] != undefined ? particularProjectTask[0].project : ''}</p>
                                <button onClick={() => setPid(null)} className='px-3 ms-auto p-2 bg-slate-950 rounded text-white'>back</button>
                            </div>
                            <TableRow obj={particularProjectTask} updateObj={updateObj} />
                        </>
                        : ''
                    }
                </> : ''

            }

            <button onClick={() => setshow(true)} className={`${status == 'admin' || status == 'manager' || status == 'head' ? '' : 'd-none'} 
             p-2 fixed w-fit ms-auto bottom-10 right-10 rounded-full bg-amber-700 text-white `}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                </svg>
            </button>
            {
                show && <Modal centered className='' show={show} onHide={() => {
                    setshow(false);
                    settask({
                        task_name: '',
                        priority: '',
                        remarks: '',
                        due_date: '',
                        created_by: JSON.parse(sessionStorage.getItem('dasid'))
                    })
                }} >
                    <Modal.Header className='p-3 ' closeButton >
                        Adding the Task {sessionStorage.getItem('selectedEmp') && sessionStorage.getItem('selectedEmp') != 'all' && sessionStorage.getItem('selectedEmp')}
                    </Modal.Header>
                    <Modal.Body className='p-3'>
                        <form action="" className='flex justify-between flex-wrap'>
                            <div className='sm:w-[45%] '>
                                Task Name : <span className='text-red-600'> * </span>
                                <input type="text" value={task.task_name} onChange={handleChange} name='task_name' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none' placeholder="Enter the task name " />
                            </div>
                            <div className='sm:w-[45%] '>
                                Priority :
                                <input type="number" value={task.priority} onChange={handleChange} name='priority'
                                    className=' border-1 p-2 block my-2 rounded w-32 nospin bg-slate-50 outline-none'
                                    placeholder="Enter in 1- 10 " />
                            </div>
                            <div className='sm:w-[45%] '>
                                Remarks :
                                <textarea type="text" value={task.remarks} onChange={handleChange} name='remarks' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none'
                                    placeholder="Enter the remarks " />
                            </div>
                            <div className='sm:w-[45%] '>
                                Due Date :
                                <input type="date" value={task.due_date} onChange={handleChange} name='due_date' className=' border-1 p-2 block my-2 rounded bg-slate-50 outline-none' placeholder="Enter the task name " />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={submitTask} className='p-2 px-3 bg-green-600 text-white rounded'>Create</button>
                        <button onClick={() => {
                            setshow(false); settask({
                                task_name: '',
                                priority: '',
                                remarks: '',
                                due_date: '',
                                created_by: JSON.parse(sessionStorage.getItem('dasid'))
                            })
                        }} className='p-2 px-3 bg-red-600 text-white rounded'>Close</button>

                    </Modal.Footer>

                </Modal>
            }
        </div>
    )
}

export default AdminTaskListing
