import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ViewTaskData from './ViewTaskData';

const PendingList = ({ data }) => {
    let navigate = useNavigate()
    let [taskid, setTaskId] = useState()
    let [subTask, setSubTask] = useState()
    return (
        <div>
            <section className='flex justify-between items-center ' >
                <h5>Pending tasks </h5>
                <button onClick={() => navigate('/dashboard/pendingTask')} className='text-sm text-blue-600 fw-semibold ' >
                    View All
                </button>
            </section>
            <hr />
            <div className='h-[70vh] scrollbar2 px-2 overflow-y-scroll '>
                {
                    data && [...data].reverse().map((x) => {
                        console.log(x);
                        return (
                            <div onClick={() => { setTaskId(x.id); }}
                                className='my-2 flex items-center hover:bg-blue-50   cursor-pointer hover:scale-[1.03] duration-500 
                                 justify-between p-2 border-2 rounded '>
                                <p className='w-1/3 text-slate-900 fw-medium '> Task Name :
                                    <span className='block text-slate-500'> {x.task_name}</span> </p>
                                <p className='w-1/3 text-slate-900 fw-medium '> Assigned to :
                                    <span className='block text-slate-500'>{x.assigned_to ? x.assigned_to.name : 'Not assigned'}</span> </p>
                                <p className='w-1/3 text-slate-900 fw-medium '> Due Date:
                                    <span className='block text-slate-500'>{x.due_date.slice(0, 10)} </span>
                                </p>
                            </div>
                        )
                    })
                }
            </div>
            {(taskid || subTask) && <ViewTaskData show={taskid} subTask={subTask}
                setSubTask={setSubTask} setshow={setTaskId} />}
        </div>
    )
}

export default PendingList