import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import ProjectList from './AdminDas2/ProjectList'
import OverdueRight from './AdminDas2/OverdueRight'
import { Store } from '../Context/StateStore'

const AdminDas3 = () => {
    let [activeset, setActiveSet] = useState('overdue')
    let [overdueData, setOverDueData] = useState()
    let [completedData, setCompletedData] = useState()
    let { notification, setnotification, getNotification } = useContext(Store)
    let getOverdue = () => {
        axios.get(`${hostName}/api/CreatedProjectsView/${JSON.parse(sessionStorage.getItem('dasid'))}/over_due/`).then((response) => {
            setOverDueData(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let getCompleted = () => {
        axios.get(`${hostName}/api/CreatedProjectsView/${JSON.parse(sessionStorage.getItem('dasid'))}/completed/`).then((response) => {
            setCompletedData(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getOverdue()
        getCompleted()

    }, [])
    return (
        <div className='my-3' >
            <main className='py-3 border-b-2 relative flex justify-around' >
                <button onClick={() => setActiveSet('overdue')} className={`relative ${activeset == 'overdue' ? 'text-orange-600  border--2 border-orange-600' : 'text-slate-500 '} transition duration-500  fw-medium `}>
                    Overdue
                    <span style={{ fontSize: '10px' }}
                        className='badge absolute bg-red-500 -top-3  ' >
                        {overdueData && overdueData.length > 0 && overdueData.length}
                    </span>
                </button>
                <button onClick={() => setActiveSet('requests')}
                    className={`relative ${activeset == 'requests' ? 'text-orange-600  border--2 border-orange-600' : 'text-slate-500 '} transition duration-500  fw-medium `}>
                    Approvals
                    <span style={{ fontSize: '10px' }}
                        className='badge absolute bg-red-500 -top-3  ' >
                        {notification && notification.filter((x, index) => x.tipe).length > 0
                            && notification.filter((x, index) => x.tipe).length}
                    </span>
                </button>
                <button onClick={() => setActiveSet('completed')} className={`relative ${activeset == 'completed' ? 'text-orange-600  border--2 border-orange-600' : 'text-slate-500'} transition duration-500  fw-medium `}>
                    Completed
                    <span style={{ fontSize: '10px' }}
                        className='badge absolute bg-red-500 -top-3  ' >
                        {completedData && completedData.length > 0 && completedData.length}
                    </span>
                </button>
            </main>
            {overdueData && activeset == 'overdue' && <OverdueRight data={overdueData} />}
            {completedData && activeset == 'completed' && <OverdueRight data={completedData} />}
            {notification && activeset == 'requests' && <OverdueRight data={notification} type="request" />}




        </div>
    )
}

export default AdminDas3