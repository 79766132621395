import React, { useContext, useEffect, useState } from 'react'
import { Offcanvas, Spinner } from 'react-bootstrap'
import SideBar from './SideBar'
import { Store } from '../Context/StateStore'
import { useNavigate } from 'react-router-dom'
import Notification from './Notification'
import axios from 'axios'
import { hostName } from '../App'
import NotifyMessage from './NotifyMessage'
import ViewTaskData from './AdminDas2/ViewTaskData'

const NavBarComponent = () => {
  let { show, setshow, apicall, user, notification,
    setnotification, getNotification } = useContext(Store)
  let [taskid, setTaskId] = useState()
  let [subTask, setSubTask] = useState()
  const [showOffcanvasnotify, setShowOffcanvasnotify] = useState(false)

  const [loading, setloading] = useState(false)
  const [declineId, setDeclineId] = useState()
  const [declineMessage, setDeclineMessage] = useState()
  const handleClose = () => {
    setshow(false)
  }
  const [allId, setAllId] = useState([])
  let navigate = useNavigate()

  useEffect(() => {
    getNotification()
  }, [])
  let removeNotification = (id) => {
    setloading(true)
    axios.post(`${hostName}/api/DeleteNotifications/${JSON.parse(sessionStorage.getItem('dasid'))}/`, id).then((response) => {
      console.log(response.data);
      getNotification()
      setloading(false)
    }).catch((error) => {
      console.log(error);
    })
  }
  let handleApprove = (nid, id, status, type) => {
    let obj1 = {
      pid: id,
      status: status,
      reason: declineMessage
    }
    let obj2 = {
      tid: id,
      status: status,
      reason: declineMessage
    }
    console.log(type == 'task' ? obj2 : obj1);
    setloading(true)
    axios.put(`${hostName}/api/UpdateTaskStatusBySuperior/${JSON.parse(sessionStorage.getItem('dasid'))}/`, type == 'task' ? obj2 : obj1).then((response) => {
      console.log(response.data);
      setAllId((prev) => [
        ...prev,
        nid
      ])
      setDeclineId('')
      setDeclineMessage('')
      getNotification()
      setloading(false)
      removeNotification([nid])
    }).catch((error) => {
      console.log(error);
    })
  }
  return (
    <div className='opacity-100 backdrop-blur-xl h-[10vh] sticky-top border-bottom border-slate-500 flex items-center justify-between px-3'>
      <img onClick={() => navigate('/dashboard')} src={require('../Assests/meridawebsitelogo.png')} width={160} alt="Logo" />
      <div className='flex items-center gap-2'>
        {/* Notification */}
        <button onClick={() => setShowOffcanvasnotify(true)} className=' relative '>
          {notification && notification.length > 0 && <p className='absolute -top-3 bg-red-500 badge text-center text-xs  left-3 rounded text-white m-0 '> {notification.length} </p>}
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-bell-fill z-10 " viewBox="0 0 16 16">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
          </svg>
        </button>
        <button onClick={() => setshow(!show)} className='p-2 d-xl-none border-1 h-fit rounded m-0'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
          </svg>
        </button>
        <button onClick={() => navigate('/Dashboard/profile')} className='rounded-full bg-black w-12 h-12 m-3 '>
          {user != undefined ?
            <img className='w-12 h-12 object-cover rounded-full' src={user.EmployeeProfile} alt="Profile" /> :
            <Spinner animation='border' />}
        </button>

      </div>

      {show && <Offcanvas style={{ backgroundColor: "#4c3575" }} show={show} className="" onHide={handleClose}>


        <Offcanvas.Header className='m-0' closeButton>
          <img className='m-0' width={160} src={require('../Assests/meridawebsitelogowhite.png')} alt="logo" />
        </Offcanvas.Header >
        <Offcanvas.Body>
          <SideBar setshow={setshow} />
        </Offcanvas.Body>
      </Offcanvas>}





      {showOffcanvasnotify &&
        <Offcanvas show={showOffcanvasnotify}
          onHide={() => { setShowOffcanvasnotify(false) }} className='transi py-0  p-0' placement='end' >
          <Offcanvas.Header closeButton>
            Notification
          </Offcanvas.Header>
          <Offcanvas.Body className='p-0 bg-white m-0'>
            {
              notification && [...notification].reverse().map((x, index) => {
                console.log(x);
                return (
                  <div key={index} className=' border-1 p-2 pt-3 rounded m-2 bg-slate-50 ' >
                    {x.message}
                    {x.tipe == true && <>
                      <div className='flex justify-end my-2'>
                        <button onClick={() => {
                          if (x.project) {
                            handleApprove(x.id, x.project, 'True', 'project')
                          }
                          else {
                            handleApprove(x.id, x.task, 'True', 'task')
                          }
                        }} className='bg-green-400 p-2 rounded text-white text-sm '>Approve</button>
                        <button onClick={() => {
                          if (declineId != x.id) {
                            setDeclineId(x.id)
                          } else {
                            setDeclineId('')
                          }
                        }} className='bg-red-400 p-2  rounded text-white text-sm mx-2'> Decline </button>
                        <button onClick={() => { setTaskId(x.project); setSubTask(x.task) }}
                          className='bg-blue-400 p-2 rounded text-white text-xs' >
                          view
                        </button>
                      </div>
                      {declineId == x.id &&
                        <div className='flex items-center gap-3'>
                          <textarea value={declineMessage} onChange={(e) => setDeclineMessage(e.target.value)} name="" className='outline-none  p-2' id="" cols="30" rows="3">

                          </textarea>
                          <button onClick={() => {
                            if (x.project) {
                              handleApprove(x.id, x.project, 'False', 'project')
                            }
                            else {
                              handleApprove(x.id, x.task, 'False', 'task')
                            }
                          }} className=' p-2 px-3 bg-blue-600 rounded text-white '>
                            {loading ? 'Loading...' : "send"}
                          </button>

                        </div>
                      }
                    </>}
                  </div>
                )
              })
            }
            {notification && notification.length > 0 &&
              <div style={{ backgroundColor: '#ffffff' }} className=' py-2 '>
                <button onClick={() => {
                  let messageArray = notification.filter((x) => x.tipe != true).map((x) => x.id)
                  // setAllId(messageArray)
                  removeNotification(messageArray)
                }}
                  className='rounded-full w-fit h-fit mx-auto flex p-2 border-1 border-slate-950 '>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>
                </button>
              </div>}

            <NotifyMessage />
            {(taskid || subTask) && <ViewTaskData show={taskid} subTask={subTask}
              setSubTask={setSubTask} setshow={setTaskId} />}

          </Offcanvas.Body>


        </Offcanvas>}
    </div>
  )
}

export default NavBarComponent