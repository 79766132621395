import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { hostName } from '../App'
import HistoryIcon from '../SVG/HistoryIcon'
import { Modal } from 'react-bootstrap'

const HistoryComponent = ({ id, type }) => {
    let [data, setData] = useState()
    let getData = () => {
        axios.get(`${hostName}/api/${type == 'project' ? 'ProjectHistory' : 'TaskHistory'}/${id}/`).then((response) => {
            console.log(response.data);
            setData(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <button onClick={getData} className='text-blue-600 ' >
                <HistoryIcon />
            </button>
            {data && <Modal centered show={data} onHide={() => setData(false)} >
                <Modal.Header className=' ' closeButton >
                    History of the Task
                </Modal.Header>
                <Modal.Body>
                    {
                        (data && data.length > 0) ? data.map((obj, index) => (
                            <div>
                                <h6>Attempt {index + 1} </h6>
                                <div className='flex  ' >
                                    <p className='w-40' >Status : </p>
                                    {obj.accepted_status ? 'Approved' : 'Declined'}
                                </div>
                                <div className='flex  ' >
                                    <p className='w-40' >Reason : </p>
                                    {obj.reason ? obj.reason : '---'}
                                </div>
                                <hr />
                            </div>
                        )) : <div>
                            No History of Approvals or Decline
                        </div>
                    }


                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default HistoryComponent