import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../Context/StateStore';
import axios from 'axios';
import { hostName } from '../App';
import Loading from '../Component/Loading';
import TableRow from '../Component/TableRow';
import ProjectTable from '../Component/ProjectTable';
import AdminTaskListing from '../Component/AdminTaskListing';
import DownloadButton from '../Component/DownloadButton';
const OverDue = () => {
  let [data, setData] = useState();
  let [cdata, setcdata] = useState();
  let status=JSON.parse(sessionStorage.getItem(`status`))

  let { setactive, completedTask,user, setCompletedtask, pendingSubTask, setpendingSubTask, apiCallTaskChangesCall } = useContext(Store);
  let [pid, setPid] = useState()
  useEffect(() => {
   if(pid) { axios.get(`${hostName}/api/SubTaskList/${pid}`).then((response) => {
      setCompletedtask(response.data)
    }).catch((error) => { console.log(error); })}
  }, [pid]);
  useEffect(() => {
    setactive("pending");
    axios.get(`${hostName}/api/PendingProjects/over_due/${JSON.parse(sessionStorage.getItem('dasid'))} `).then((response) => {
      setData(response.data)
      console.log(response.data);
    }).catch((error) => console.log(error))
  }, []);
  const updateObj = (index, newValue) => {
    const newObj = [...completedTask];
    newObj[index].remarks = newValue;
    setCompletedtask(newObj);
  };
  return (
    <div className="poppins p-3">
      {
        data != undefined & data != null ? <>

          <h3>OverDue pending Task </h3>
          {/* Add more rows as needed */}
          { data != undefined && data != undefined&& user&&
         (status.toLowerCase() == 'employee' 
         || status.toLowerCase() == 'team_leader') &&
         <DownloadButton data={data} 
         name={`Over_due_${user.name}`} />}
          {data != undefined && pid == null && status!='admin' ?
            <ProjectTable setPid={setPid} data={data} type='own'/> : ''
          }
          {completedTask != undefined && pid != null ?
            <TableRow obj={completedTask} updateObj={updateObj}/>
            : ''
          }    </>
          : <Loading />
      }
      {status.toLowerCase()!='employee' && status.toLowerCase()!='team_leader' && <div className={` `}>
          <AdminTaskListing mas='over_due' />

          </div>}
    </div>
  )
}

export default OverDue