import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { hostName } from '../../App';
import { Store } from '../../Context/StateStore';
import ViewTaskData from './ViewTaskData';

const OverdueRight = ({ data, type }) => {
    let navigate = useNavigate()
    let [taskid, setTaskId] = useState()
    let [subTask, setSubTask] = useState()
    let { getNotification } = useContext(Store)
    const [loading, setloading] = useState(false)
    const [declineId, setDeclineId] = useState()
    const [declineMessage, setDeclineMessage] = useState()
    const [allId, setAllId] = useState([])
    let removeNotification = (id) => {
        setloading(true)
        axios.post(`${hostName}/api/DeleteNotifications/${JSON.parse(sessionStorage.getItem('dasid'))}/`, id).then((response) => {
            console.log(response.data);
            getNotification()
            setloading(false)
        }).catch((error) => {
            console.log(error);
        })
    }
    let handleApprove = (nid, id, status, type) => {
        let obj1 = {
            pid: id,
            status: status,
            reason: declineMessage
        }
        let obj2 = {
            tid: id,
            status: status,
            reason: declineMessage
        }
        console.log(type == 'task' ? obj2 : obj1);
        setloading(true)
        axios.put(`${hostName}/api/UpdateTaskStatusBySuperior/${JSON.parse(sessionStorage.getItem('dasid'))}/`, type == 'task' ? obj2 : obj1).then((response) => {
            console.log(response.data);
            setAllId((prev) => [
                ...prev,
                nid
            ])
            setDeclineId('')
            setDeclineMessage('')
            getNotification()
            setloading(false)
            removeNotification([nid])
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <div className='h-[80vh] px-2 scrollbar2 overflow-y-scroll '>
                {
                    !type && data && [...data].reverse().map((x) => {
                        console.log(x,'com');
                        return (
                            <div className='py-2 p-2 border-y-[1px] '>
                                <p className=' text-slate-900 fw-medium '> Task Name :
                                    <span className=' text-slate-500'> {x.task_name}</span> </p>
                                <p className=' text-slate-900 fw-medium '> Assigned to :
                                    <span className=' text-slate-500'> {x.assigned_to ? x.assigned_to.name : 'Not assigned'}</span> </p>
                                <div className='flex items-center justify-between'>
                                    <p className=' text-slate-900 fw-medium mb-0'> Deadline :
                                        <span className=' text-slate-500'> {x.due_date.slice(0, 10)} </span>
                                    </p>
                                    {x.assigned_to &&
                                        <button onClick={() => navigate(`/dashboard/chat/${x.assigned_to.employee_id}`)} className='p-1 px-3 bg-blue-500 rounded text-white '>
                                            chat
                                        </button>
                                    }
                                    <button onClick={() => { setTaskId(x.id);}}
                                        className='bg-blue-400 p-2 rounded text-white text-xs' >
                                        view
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    type && data && [...data].reverse().filter((x, index) => x.tipe).map((x, index) => {
                        console.log(x);
                        return (
                            <div key={index} className=' border-1 p-2 pt-3 rounded m-2 bg-slate-50 ' >
                                {x.message}
                                {x.tipe == true && <>
                                    <div className='flex justify-end my-2'>
                                        <button onClick={() => {
                                            if (x.project) {
                                                handleApprove(x.id, x.project, 'True', 'project')
                                            }
                                            else {
                                                handleApprove(x.id, x.task, 'True', 'task')
                                            }
                                        }} className='bg-green-400 p-2 rounded text-white text-xs '>Approve</button>
                                        <button onClick={() => {
                                            if (declineId != x.id) {
                                                setDeclineId(x.id)
                                            } else {
                                                setDeclineId('')
                                            }
                                        }} className='bg-red-400 p-2 rounded text-white text-xs mx-2'> Decline </button>
                                        <button onClick={() => { setTaskId(x.project); setSubTask(x.task) }}
                                            className='bg-blue-400 p-2 rounded text-white text-xs' >
                                            view
                                        </button>
                                    </div>
                                    {declineId == x.id &&
                                        <div className='flex items-center gap-3'>
                                            <textarea value={declineMessage}
                                                onChange={(e) => setDeclineMessage(e.target.value)} name="" className='outline-none  p-2' id="" cols="30" rows="3">
                                            </textarea>
                                            <button onClick={() => {
                                                if (x.project) {
                                                    handleApprove(x.id, x.project, 'False', 'project')
                                                }
                                                else {
                                                    handleApprove(x.id, x.task, 'False', 'task')
                                                }
                                            }} className=' p-2 px-3 bg-blue-600 rounded text-white '>
                                                {loading ? 'Loading...' : "send"}
                                            </button>
                                        </div>
                                    }
                                </>}
                            </div>
                        )
                    })
                }
                {(taskid || subTask) && <ViewTaskData show={taskid} subTask={subTask}
                    setSubTask={setSubTask} setshow={setTaskId} />}
            </div>
        </div>
    )
}

export default OverdueRight