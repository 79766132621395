import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../Context/StateStore';
import axios from 'axios';
import { hostName } from '../App';
import Loading from '../Component/Loading';
import TableRow from '../Component/TableRow';
import ProjectTable from '../Component/ProjectTable';
import AdminTaskListing from '../Component/AdminTaskListing';
import { useNavigate } from 'react-router-dom';
import DownloadButton from '../Component/DownloadButton';
const CompletedTask = (props) => {
  let { setactive, completedTask,user, setParticularProjectTask, setCompletedtask, particularProjectTask, pendingSubTask, setpendingSubTask, apiCallTaskChangesCall } = useContext(Store);
  let [data, setdata] = useState();
  let [pid, setPid] = useState()
  useEffect(() => {
    if (pid) {
      axios.get(`${hostName}/api/SubTaskList/${pid}`).then((response) => {
        setCompletedtask(response.data)
      }).catch((error) => { console.log(error); })
    }
  }, [pid]);
  let status = JSON.parse(sessionStorage.getItem('status'))
  useEffect(() => {
    setactive("completed");
    axios.get(`${hostName}/api/PendingProjects/completed/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
      console.log(response.data);
      setdata(response.data)
    }).catch((error) => { console.log(error); })
  }, []);
  const updateObj = (index, newValue) => {
    const newObj = [...particularProjectTask];
    console.log(newValue);
    console.log(newObj[0].remarks);
    newObj[index].remarks = newValue;
    setParticularProjectTask(newObj);
  };
let navigate=useNavigate()
  return (
    <div className="poppins p-3">
      <div className='flex flex-wrap justify-between gap-3'>
        <h3>Completed Task List </h3>
        <div className='flex gap-3'>

          <button>
          <button onClick={() => { navigate('/dashboard/completedAfterTime'); }} className='bg-yellow-300 text-black shadow-sm py-2 mx-2 px-3 rounded-lg '>Completed after Time </button>
          <button onClick={() => { navigate('/dashboard/completedOntime'); }} className='bg-blue-300 py-2 text-black shadow-sm px-3 rounded-lg '>Completed on Time </button>
      
          </button>
        </div>
      </div>
      { data != undefined && data != undefined&& user&&
         (status.toLowerCase() == 'employee' 
         || status.toLowerCase() == 'team_leader') &&
         <DownloadButton data={data} 
         name={`Completed_${user.name}`} />}
      {data != undefined && pid == null && status != 'admin' ?
        <ProjectTable setPid={setPid} data={data} type='own' /> : ''
      }
      {particularProjectTask != undefined && pid != null ?
        <TableRow obj={particularProjectTask} type='details' updateObj={updateObj} />
        : ''
      }

      {/* Add more rows as needed */}

      {status.toLowerCase() != 'employee' && status.toLowerCase() != 'team_leader' && <div className={` `}>
        <AdminTaskListing mas='completed' />

      </div>}

    </div>
  )
}

export default CompletedTask