import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { hostName } from '../App';
import Loading from '../Component/Loading';
import TableRow from '../Component/TableRow';
import { Store } from '../Context/StateStore';
import { Modal } from 'react-bootstrap';

const TaskDetails = () => {
  const { id } = useParams()
  let { setactive, apiCallTaskChangesCall } = useContext(Store)
  let navigate = useNavigate()
  let [errorMessage,seterrorMessage]=useState()
  const [obj, setObj] = useState({
    task_list: id,
    task_name: "",
    remarks: '',
    priority: null,
    due_date: null,
  })
  const handleChange = (e) => {
    let { name, value } = e.target
    if (name == 'priority' && value > 10) {
      value = 10;
    }
    if (name == 'priority' && value < 0) {
      value = 0;
    }
    setObj((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false);};
  const handleShow = () => setShow(true);
  let [projectDetails, setdetails] = useState()
  console.log(id);
  useEffect(() => {
    setactive('task')
    axios.get(`${hostName}/api/SubTaskList/${id}/`).then((response) => {
      console.log(response.data);
      setdetails(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }, [])
  const updateObj = (index, newValue) => {
    const newObj = [...projectDetails];
    newObj[index].remarks = newValue;
    setdetails(newObj);
  };
  const addTask = () => {
    if (obj.due_date != null && obj.priority != null && obj.remarks != '' && obj.task_list != '') {
      axios.post(`${hostName}/api/CreateTasks/${JSON.parse(sessionStorage.getItem('dasid'))}/`, obj).then((response) => {
        console.log(response.data);
        window.location.reload()
      }).catch((error) => {
        console.log(error);
      })
    }
    else{
      seterrorMessage("*Enter all the fields")
    }
  }
  return (
    <div className="poppins p-3">
      {
        projectDetails != undefined ? <>


          <h3>Sub Task Management</h3>
          <TableRow obj={projectDetails} type='details' updateObj={updateObj} />
          {/* Add more rows as needed */}
          {/* Adding the task button */}
          <button onClick={handleShow}
          className='border-1 w-2/ fixed right-10 bottom-10 bg-yellow-700 text-white hover:scale-105 transition duration-500 rounded-full p-2 '> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
          </svg> </button>
         {
          show&& <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title> Task adding form </Modal.Title>
          </Modal.Header>
          <Modal.Body className='flex justify-between flex-wrap'>
            <div>
              Task Name :
              <input type="text" name='task_name' onChange={handleChange} className='p-3 rounded block my-2 outline-none bg-slate-50 ' />
            </div>
            <div>
              Priority :
              <input type="number" value={obj.priority} name='priority' onChange={handleChange} className='p-3 noarrow rounded block my-2 outline-none bg-slate-50 ' />
            </div>
            <div>
              Due Date :
              <input type="date" name='due_date' onChange={handleChange} className='p-3 noarrow rounded block my-2 outline-none bg-slate-50 ' />
            </div>
            <div>
              Remarks :
              <input type="text" name='remarks' onChange={handleChange} className='p-3 noarrow rounded block my-2 outline-none bg-slate-50 ' />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <p className='text-red-600'>{errorMessage} </p>
            <button className='p-2 px-3 bg-red-600 text-white rounded' onClick={handleClose}>
              Close
            </button>
            <button onClick={addTask} className='p-2 px-3 rounded bg-slate-600 text-white ' > ADD</button>
            <p className='text-red-600 h-[30px]' id="errormsg" ></p>
          </Modal.Footer>
        </Modal>
         }

        </> : <Loading />
      }

    </div>
  )
}

export default TaskDetails