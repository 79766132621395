import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { hostName } from '../../App'
import PlusIcon from '../../SVG/PlusIcon'
import { Store } from '../../Context/StateStore'
import SearchIcon from '../../SVG/SearchIcon'

const EmployeeView = () => {
    let { id, mid } = useParams()
    let { setactive } = useContext(Store)
    console.log(id, "hellow");

    let status = JSON.parse(sessionStorage.getItem('status'))
    let navigate = useNavigate()
    let [allEmpy, setAllEmp] = useState([])
    let [filteredEmp, setFilteredEmp] = useState([])
    let [filterword, setFilterWord] = useState('')
    useEffect(() => {
        setactive('employee')
        console.log(id, 'id');
        console.log(mid, 'id');

        if (id == undefined) {
            axios.get(`${hostName}/api/AdminEmployees/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
                setAllEmp(response.data)
                console.log(response.data);
                setFilteredEmp(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
        if (id != 'blocked') {
            axios.get(`${hostName}/api2/DepartmentEmployee/${id}/${JSON.parse(sessionStorage.getItem('dasid'))}`).then((response) => {
                console.log(response.data);
                setAllEmp(response.data)
                setFilteredEmp(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }

        if (id == 'blocked') {
            axios.get(`${hostName}/api2/BlockedUsers/${JSON.parse(sessionStorage.getItem('dasid'))}/`).then((response) => {
                console.log(response.data);
                setAllEmp(response.data)
                setFilteredEmp(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }

    }, [id, mid])
    let getFilterEmp = () => {
        if (filterword != '') {
            let filteredData = allEmpy.filter((obj) => obj.name.toLowerCase().indexOf(filterword.toLowerCase()) != -1)
            setFilteredEmp(filteredData)
        } else {
            setFilteredEmp(allEmpy)
        }

    }
    return (
        <div className='min-h-[88vh] container mx-auto'>
            {
                allEmpy && allEmpy.length > 0 && <>
                    <div className='justify-between my-3 flex'>
                        <h6 className=' text-2xl w-fit'>{allEmpy[0].department && allEmpy[0].department.department_name} </h6>
                        <article className='flex gap-2'>
                            <div className=' border-2 border-violet-300 rounded pe-2  p-1 flex items-center '>
                                <input onKeyDown={(e) => {
                                    if (e.key == 'Enter') {
                                        getFilterEmp()
                                    }
                                }} value={filterword} onChange={(e) => setFilterWord(e.target.value)} type="text" className='p-1 bg-transparent outline-none' placeholder='Search ' />
                                <span className='' onClick={getFilterEmp}> <SearchIcon /> </span>
                            </div>
                            {/* {status == 'admin' && <button onClick={() => navigate('/registeration')} className='flex items-center py-1 bg-white rounded-full text-slate-500 fw-semibold px-3 gap-2 text-xs '>
                                <span className='bg-violet-600 p-1 text-white rounded-full'><PlusIcon size={10} /> </span>
                                Add New Member
                            </button>} */}
                        </article>
                    </div>
                    <main>

                        <section className='flex flex-wrap gap-3 justify-around sm:justify-between'>
                            {
                                filteredEmp && Array.isArray(filteredEmp) && filteredEmp.map((obj) => {
                                    return (
                                        <div className={` ${obj.status == 'employee' ? 'bg-blue-50' : 'bg-yellow-50 bg-opacity-80'} text-center rounded-lg p-3 w-[15rem] `}>
                                            <div className='relative w-fit h-fit mx-auto '>
                                                <img className='w-20 h-20 rounded-full object-cover mx-auto  '
                                                    src={obj.profile_pic ? obj.profile_pic : require('../../Assests/profilepic.webp')} alt="Profile pic" />
                                                <img src={require('../../Assests/favicon.ico')} alt="Logo Icon"
                                                    className='rounded-full w-7 absolute bottom-0 right-0 border-2 bg-white  h-7 ' />
                                            </div>
                                            <p className='mb-1 text-lg fw-semibold'>{obj.name} </p>
                                            <p className='mb-1'>{obj.position.position} </p>
                                            <p className='mb-1'>{obj.status} </p>
                                            <button onClick={() => navigate(`/dashboard/employee/${obj.employee_id}`)}
                                                className='bg-white shadow-sm text-sm px-3 py-1 rounded-full'>View Profile </button>

                                        </div>
                                    )
                                })
                            }

                        </section>
                    </main>
                </>
            }
            {
                allEmpy && allEmpy.length <= 0 && <div className='flex min-h-[40vh] '>
                    <h5 className='m-auto'>No Employees in this department </h5>
                </div>
            }
        </div>
    )
}

export default EmployeeView