import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import StateStore, { Store } from './Context/StateStore';
import Login from './Pages/Login';
import Registration from './Pages/Registeration';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Pages/page.css'
import Dashboard from './Pages/Dashboard';
import TaskList from './Pages/TaskList';
import Protect from './Component/Protect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotifyMessage from './Component/NotifyMessage';
import 'flatpickr/dist/flatpickr.min.css';
import Hrms from './Pages/Hrms';

export const hostName = 'https://dasbackendapi.meridahr.com'
export const hrapi = 'https://hrmbackendapi.meridahr.com'
// export const hrapi = 'http://192.168.18.17:9000'
// export const hostName = 'http://192.168.18.12:9000'
// export const hostName=`https://interview.meridatechminds.com/`
export const hrpage = 'https://hrm.meridahr.com'
// export const hrpage = 'http://192.168.18.24:3000'

function App() {
  // useEffect(()=>{
  //  window.addEventListener('beforeunload',sessionStorage.removeItem('user'))
  // },[])
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/hrms' element={<Hrms />} />
          <Route path='/registeration' element={<Registration />} />
          <Route path='/dashboard/*' element={<Protect Child={Dashboard} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
