import React from 'react'
import Login from '../Pages/Login'

const Protect = ({Child}) => {
    let user=JSON.parse(sessionStorage.getItem('dasid'))

  return (
    <div>
        {user!=null&&user!=undefined ? <Child/> : <Login/> }
      
    </div>
  )
}

export default Protect
