import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '../../SVG/SearchIcon'

const Members = ({ data }) => {
    let navigate = useNavigate()
    let [filteredArray, setFilteredArray] = useState(data)
    useEffect(() => {
        setFilteredArray(data)
    }, [data])
    let [filterword, setfilterword] = useState()
    let filterArry = () => {
        let filterData = data.filter((obj) => obj.name.toLowerCase().indexOf(filterword.toLowerCase())!=-1)
        console.log(filterData);
        setFilteredArray(filterData)
    }
    return (
        <div>
            <div className='flex justify-between pe-2'>
                <h5>Members </h5>
                <article className='p-2 flex items-center border-2 rounded-lg'>
                    <input value={filterword} onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                            filterArry()
                        }
                    }}
                        onChange={(e) => setfilterword(e.target.value)}
                        placeholder='search' type="text" className='bg-transparent outline-none ' />
                    <SearchIcon />
                </article>
            </div>
            <div className='h-[70vh] scrollbar2 overflow-y-scroll '>
                {
                    filteredArray && [...filteredArray].map((x) => {
                        console.log(x);
                        return (
                            <div className='my-3 border-2 rounded-lg p-3 flex items-center justify-between  '>
                                <div className='flex gap-2 items-center'>
                                    <img src={x.profile_pic ? x.profile_pic : require('../../Assests/profilepic.webp')} alt="Profile pic" className='w-12 h-12 rounded-full object-cover  ' />
                                    <div>
                                        <p className='text-xl fw-semibold mb-1 text-start text-slate-600 '> {x.name} </p>
                                        <p className='mb-1 text-slate-500 fw-medium text-start'> {x.department.department_name} ({x.position.position}) </p>
                                    </div>
                                </div>
                                <button onClick={() => navigate(`/dashboard/employee/${x.employee_id}`)} className='p-1 bg-slate-200 text-slate-600 rounded-full px-3'>
                                    view
                                </button>
                                {/* <p>{x.due_date.slice(0, 10)} </p> */}
                            </div>
                        )
                    })
                }
            </div>



        </div>
    )
}

export default Members