import React, { useContext, useState } from 'react';
import { Store } from '../Context/StateStore';
import { useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import HomeIcon from '../SVG/HomeIcon';
import ArrowRghtIconSVG from '../SVG/ArrowRghtIconSVG';
import { CalendarIcon } from '@mui/x-date-pickers';
import ProfileIcon from '../SVG/ProfileIcon';
import SettingIcon from '../SVG/SettingIcon';
import TaskBarIcon from '../SVG/TaskBarIcon';
import ChatIcon from '../SVG/ChatIcon';
import SignOut from '../SVG/SignOut';
import { hrpage } from '../App';
import HrmsLogo from '../SVG/HrmsLogo';

const SideBar = () => {
  const { user, show, setshow, activepage, setactive } = useContext(Store);
  const status = JSON.parse(sessionStorage.getItem('status'));
  const [showPending, setShowPending] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [width, setWidth] = useState('w-[70px]');
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('dasid');
    sessionStorage.removeItem('daspk');

    window.location.reload();
  };

  const handleNavigate = (path, page) => {
    navigate(path);
    setactive(page);
  };

  const renderTooltip = (text) => (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  );

  const iconSize = 20;

  return (
    <div className="h-[100vh] sticky top-0">
      <div
        style={{ backgroundColor: 'rgb(252,246,248)' }}
        className={`sm:h-[95vh] h-full ${width} my-3 overflow-y-scroll scrollbar sticky top-[12vh] py-3 flex flex-col items-center justify-between poppins shadow-sm-2xl sm:shadow-violet-500 rounded transi mx-3 p-2`}
      >
        {/* Top Section */}
        <section>
          <div className="flex my-2">
            <img
              onClick={() => navigate('/dashboard')}
              src={
                width === 'w-[70px]'
                  ? require('../Assests/favicon.ico')
                  : require('../Assests/meridawebsitelogo.png')
              }
              width={width === 'w-[70px]' ? 28 : 118}
              className="mx-auto"
              alt="Icon"
            />
          </div>
          <section className='overflow-y-scrol' >

          </section>
          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Dashboard')}>
            <div
              onClick={() => handleNavigate('/dashboard/', 'dashboard')}
              className={`flex transi my-2 mt-2 gap-3 cursor-pointer p-[15px] rounded ${activepage === 'dashboard' ? 'bg-violet-700 text-slate-50' : 'text-violet-700'
                } items-center`}
            >
              <HomeIcon size={iconSize} />
              <p className={`transi m-0 text-sm ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Dashboard</p>
            </div>
          </OverlayTrigger>

          {status === 'admin' && (
            <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }}
              overlay={renderTooltip('Employee Details')}>
              <div
                onClick={() => handleNavigate('/dashboard/department', 'employee')}
                className={`flex transi my-2 gap-3 cursor-pointer p-[15px] rounded ${activepage === 'employee' ? 'bg-violet-700 text-slate-50' : 'text-violet-700'
                  } items-center`}
              >
                <ProfileIcon size={iconSize} />
                <p className={`transi text-sm m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Employee Details</p>
              </div>
            </OverlayTrigger>
          )}

          {status !== 'admin' && status !== 'employee' && (
            <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Employee Details')}>
              <div
                onClick={() => handleNavigate('/dashboard/reporting', 'employee')}
                className={`flex transi my-2 gap-3 cursor-pointer p-[15px] rounded ${activepage === 'employee' ? 'bg-violet-700 text-slate-50' : 'text-violet-700'
                  } items-center`}
              >
                <ProfileIcon size={iconSize} />
                <p className={`transi text-sm m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Employee Details</p>
              </div>
            </OverlayTrigger>
          )}

          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Chat')}>
            <div
              onClick={() => handleNavigate('/dashboard/chat', 'chat')}
              className={`flex transi my-2 gap-3 cursor-pointer p-[15px] rounded 
                ${activepage === 'chat' ? 'bg-violet-700 text-slate-50' : 'text-violet-700'
                } items-center`}
            >
              <ChatIcon size={iconSize} />
              <p className={`transi text-sm m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Message</p>
            </div>
          </OverlayTrigger>

          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Daily Activity Sheet')}>
            <div
              onClick={() => handleNavigate('/dashboard/dasreport', 'das')}
              className={`flex transi my-2 gap-3 cursor-pointer p-[15px] rounded ${activepage === 'das' ? 'bg-violet-700 text-slate-50' : 'text-violet-700'
                } items-center`}
            >
              <CalendarIcon size={iconSize} />
              <p className={`transi text-sm m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>DAS</p>
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }}
            overlay={renderTooltip('HRMS')}>
            <a
              href={`${hrpage}/das?user=${JSON.parse(sessionStorage.getItem('dasid'))}&password=${JSON.parse(sessionStorage.getItem('user')).Password}`}
              className={`flex transi my-2 gap-3 cursor-pointer p-[15px] rounded ${activepage === 'hrms' ? 'bg-violet-700 text-slate-50' : 'text-violet-700'
                } items-center`}
            >
              <HrmsLogo size={iconSize} />
              <p className={`transi text-sm m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>HRMS</p>
            </a>
          </OverlayTrigger>

          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Log out')}>
            <div
              onClick={logout}
              className={`flex transi my-2 gap-3 cursor-pointer p-[15px] rounded 
                ${activepage === 'setting' ? 'bg-violet-700 text-slate-50' : 'text-violet-700'
                } items-center`} >
              <SignOut size={iconSize} />
              <p className={`transi text-sm m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Log out</p>
            </div>
          </OverlayTrigger>

          {/* Toggle Button */}
          <div
            onClick={() => setWidth(width === 'w-[70px]' ? 'w-[260px]' : 'w-[70px]')}
            className={`flex my-2 gap-3 p-[15px] rounded cursor-pointer bg-slate-700 text-slate-50 items-center`}
          >
            <button className={`transi ${width === 'w-[70px]' ? '' : 'rotate-180'}`}>
              <ArrowRghtIconSVG />
            </button>
            <p className={`text-sm m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Close</p>
          </div>
        </section>

        <hr className="border-slate-950 text-slate-950" />

        {/* Middle Section */}
        <section className="border-t-2">
          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Task List')}>
            <div
              onClick={() => handleNavigate('/dashboard/tasklist', 'task')}
              className={`flex transi my-2 mt-3 gap-3 cursor-pointer p-[10px] w-fit rounded ${activepage === 'task' ? 'bg-violet-700 text-slate-50' : 'text-slate-600 bg-slate-100'
                } items-center`}
            >
              <TaskBarIcon size={iconSize} />
              <p className={`text-xs m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Task List</p>
            </div>
          </OverlayTrigger>

          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Pending task')}>
            <div
              onClick={() => handleNavigate('/dashboard/pendingTask', 'pending')}
              className={`flex transi my-2 mt-3 gap-3 cursor-pointer p-[10px] w-fit rounded ${activepage === 'pending' ? 'bg-violet-700 text-slate-50' : 'text-red-600 bg-red-100'
                } items-center`}
            >
              <TaskBarIcon size={iconSize} />
              <p className={`text-xs m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Pending task</p>
            </div>
          </OverlayTrigger>

          <OverlayTrigger placement="right" delay={{ show: 150, hide: 200 }} overlay={renderTooltip('Completed task')}>
            <div
              onClick={() => handleNavigate('/dashboard/completedTask', 'completed')}
              className={`flex transi my-2 mt-3 gap-3 cursor-pointer p-[10px] w-fit rounded ${activepage === 'completed' ? 'bg-violet-700 text-slate-50' : 'text-blue-600 bg-blue-100'
                } items-center`}
            >
              <TaskBarIcon size={iconSize} />
              <p className={`text-xs m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>Completed task</p>
            </div>
          </OverlayTrigger>
        </section>

        {/* Bottom Section */}
        <section className="border-t-2">
          <div
            onClick={() => handleNavigate('/dashboard/profile', 'profile')}
            className={`flex transi my-2 mt-3 gap-3 cursor-pointer p-[1px] rounded items-center`}
          >
            {user && (
              <>
                <img className="w-12 h-12 bg-red-400 object-cover rounded-full" src={user.EmployeeProfile}
                  alt="Profile" />
                <div className={`m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>
                  <p className={`m-0 ${width === 'w-[70px]' ? 'hidden' : 'w-[160px]'}`}>{user.name}</p>
                  <span className="text-xs">{user.Department}</span>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SideBar;
